<template>
  <div
    class="home px-3"
    v-loading="loading"
    element-loading-text="数据拼命加载中，请稍等~"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- <avue-data-box v-if="IsPC" :option="option"></avue-data-box> -->
    <div class="d-flex justify-content-end">
      <el-button @click="dataAnalysis" type="success" plain class="mr-3"
        >数据分析</el-button
      >
      <div class="month ml-2">
        <span class="demonstration mr-2 fs-9">当前数据日期</span>
        <el-date-picker
          v-model="dataDate.dataMonth"
          type="date"
          :clearable="false"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
    </div>
    <div v-if="IsPC" class="w-100">
      <el-row :gutter="24" class="card-item">
        <el-col
          v-for="item in option.data"
          :xs="24"
          :md="IsPC ? item.span : 12"
          :lg="{ span: '4-8' }"
          :sm="12"
          :key="item.title"
          class="py-2"
        >
          <el-card
            style="min-height: 100px"
            class="card mt-2 d-flex justify-content-center"
            v-if="item.isShow !== 8 && item.type !== 'meTarget'"
          >
            <div v-if="item.type == 'target' || item.type == 'yearTarget'">
              <div class="d-flex align-items-center">
                <div class="icon mr-1">
                  <span
                    class="iconfont icon-line-084 text-white"
                    style="display: inline-block; transform: scale(0.5)"
                  ></span>
                </div>
                <div class="option-title d-flex">{{ item.title }}</div>

                <el-tooltip
                  content="编辑"
                  class="ml-auto"
                  v-if="[0, 8, 5].includes(user.role)"
                >
                  <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click="editItem(item)"
                  ></el-button>
                </el-tooltip>

                <el-tooltip content="查看" v-if="[8, 5].includes(user.role)">
                  <el-button
                    type="text"
                    icon="el-icon-view"
                    @click="to(item)"
                  ></el-button>
                </el-tooltip>
              </div>

              <div class="d-flex justify-content-between pt-2">
                <div>
                  <span class="fs-9">{{ item.label2 }}</span>
                  <span class="option-count fs-7 fw-bold text-primary">{{
                    item.target_value > 0 ? item.target_value : "未设定"
                  }}</span>
                  <span v-if="item.target_value > 0" class="pl-1">{{
                    item.unit
                  }}</span>
                </div>

                <div class="d-flex align-items-center">
                  <span class="fs-9">{{ item.label1 }}</span>
                  <div>
                    <span
                      class="fs-7 text-primary pr-1"
                      style="font-weight: 500"
                      >{{ item.count }}</span
                    >
                    <span class="fs-9">{{ item.unit }}</span>
                  </div>
                </div>
              </div>

              <div>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :format="fromat(item.percentage)"
                  :percentage="item.percentage > 100 ? 100 : item.percentage"
                ></el-progress>
              </div>
            </div>

            <div
              v-else-if="user.role == 5 && item.type == 'meTarget'"
              @click="to(item)"
            >
              <div class="d-flex align-items-center">
                <div class="icon mr-1">
                  <span
                    class="iconfont icon-line-084 text-white"
                    style="display: inline-block; transform: scale(0.5)"
                  ></span>
                </div>
                <div class="option-title">{{ item.title }}</div>
              </div>

              <div class="d-flex justify-content-between pt-2">
                <div>
                  <span class="fs-9">{{ item.label2 }}</span>
                  <span class="option-count fs-7 fw-bold text-primary">{{
                    item.target_value > 0 ? item.target_value : "未设定"
                  }}</span>
                  <span v-if="item.target_value > 0" class="pl-1">{{
                    item.unit
                  }}</span>
                </div>

                <div class="d-flex align-items-center">
                  <span class="fs-9">{{ item.label1 }}</span>
                  <div>
                    <span
                      class="fs-7 text-primary pr-1"
                      style="font-weight: 500"
                      >{{ item.count }}</span
                    >
                    <span class="fs-9">{{ item.unit }}</span>
                  </div>
                </div>
              </div>

              <div>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :format="fromat(item.percentage)"
                  :percentage="item.percentage > 100 ? 100 : item.percentage"
                ></el-progress>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-content-between align-items-center flex-auto"
              @click="to(item)"
            >
              <div>
                <el-image :src="item.url" class="img-size"></el-image>
              </div>
              <div class="cursor-pointer">
                <div class="option-title pb-2">{{ item.title }}</div>
                <div class="d-flex">
                  <div style="min-width: 120px">
                    <span class="option-count text-primary pr-1">{{
                      item.count
                    }}</span>
                    <span class="fs-10">{{ item.unit }}</span>
                    <div class="fs-10">{{ item.label1 }}</div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </el-card>

          <el-card
            style="min-height: 100px"
            class="card mt-2 d-flex justify-content-center"
            v-if="item.isShow !== 8 && item.type == 'meTarget'"
          >
            <div
              v-if="user.role == 5 && item.type == 'meTarget'"
              @click="to(item)"
            >
              <div class="d-flex align-items-center">
                <div class="icon mr-1">
                  <span
                    class="iconfont icon-line-084 text-white"
                    style="display: inline-block; transform: scale(0.5)"
                  ></span>
                </div>
                <div class="option-title">{{ item.title }}</div>
              </div>

              <div class="d-flex justify-content-between pt-2">
                <div>
                  <span class="fs-9">{{ item.label2 }}</span>
                  <span class="option-count fs-7 fw-bold text-primary">{{
                    item.target_value > 0 ? item.target_value : "未设定"
                  }}</span>
                  <span v-if="item.target_value > 0" class="pl-1">{{
                    item.unit
                  }}</span>
                </div>

                <div class="d-flex align-items-center">
                  <span class="fs-9">{{ item.label1 }}</span>
                  <div>
                    <span
                      class="fs-7 text-primary pr-1"
                      style="font-weight: 500"
                      >{{ item.count }}</span
                    >
                    <span class="fs-9">{{ item.unit }}</span>
                  </div>
                </div>
              </div>

              <div>
                <el-progress
                  :text-inside="true"
                  :stroke-width="16"
                  :format="fromat(item.percentage)"
                  :percentage="item.percentage > 100 ? 100 : item.percentage"
                ></el-progress>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div v-else class="w-100">
      <el-row>
        <el-card>
          <el-col
            :span="IsPC ? 6 : 12"
            v-for="item in option.data"
            :key="item.title"
            class="py-3"
          >
            <div
              class="d-flex align-items-center"
              v-if="item.isShow !== 8 && item.type !== 'meTarget'"
              @click="to(item)"
            >
              <div
                v-if="item.type == 'target' || item.type == 'yearTarget'"
                @click="to(item)"
              >
                <div class="d-flex align-items-center">
                  <div class="icon mr-1">
                    <span
                      class="iconfont icon-line-084 text-white"
                      style="display: inline-block; transform: scale(0.5)"
                    ></span>
                  </div>
                  <div class="option-title">{{ item.title }}</div>
                </div>

                <div class="pt-2">
                  <div>
                    <span class="fs-10">{{ item.label2 }}</span>
                    <span class="option-count text-primary">{{
                      item.target_value > 0 ? item.target_value : "未设定"
                    }}</span>
                    <span v-if="item.target_value > 0" class="pl-1 fs-10">{{
                      item.unit
                    }}</span>
                  </div>

                  <div class="d-flex align-items-center">
                    <div class="fs-10">{{ item.label1 }}</div>
                    <div>
                      <span
                        class="option-count text-primary pr-1"
                        style="font-weight: 500"
                        >{{ item.count }}</span
                      >
                      <span class="fs-10">{{ item.unit }}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <el-progress
                    :text-inside="true"
                    :stroke-width="16"
                    :format="fromat(item.percentage)"
                    :percentage="item.percentage > 100 ? 100 : item.percentage"
                  ></el-progress>
                </div>
              </div>

              <div
                v-else-if="user.role == 5 && item.type == 'meTarget'"
                @click="to(item)"
              >
                <div class="d-flex align-items-center">
                  <div class="icon mr-1">
                    <span
                      class="iconfont icon-line-084 text-white"
                      style="display: inline-block; transform: scale(0.5)"
                    ></span>
                  </div>
                  <div class="option-title">{{ item.title }}</div>
                </div>

                <div class="d-flex justify-content-between pt-2">
                  <div>
                    <span class="fs-9">{{ item.label2 }}</span>
                    <span class="option-count fs-7 fw-bold text-primary">{{
                      item.target_value > 0 ? item.target_value : "未设定"
                    }}</span>
                    <span v-if="item.target_value > 0" class="pl-1">{{
                      item.unit
                    }}</span>
                  </div>

                  <div class="d-flex align-items-center">
                    <span class="fs-9">{{ item.label1 }}</span>
                    <div>
                      <span
                        class="fs-7 text-primary pr-1"
                        style="font-weight: 500"
                        >{{ item.count }}</span
                      >
                      <span class="fs-9">{{ item.unit }}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <el-progress
                    :text-inside="true"
                    :stroke-width="16"
                    :format="fromat(item.percentage)"
                    :percentage="item.percentage > 100 ? 100 : item.percentage"
                  ></el-progress>
                </div>
              </div>
              <div class="d-flex" v-else>
                <div class="mr-3">
                  <el-image :src="item.url" class="img-size"></el-image>
                </div>
                <div class="cursor-pointer">
                  <div class="option-title">
                    {{ item.title.replace("（万元）", "") }}
                  </div>
                  <div>
                    <span class="option-count text-primary">{{
                      item.count
                    }}</span>
                    <span class="fs-10">{{ item.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center"
              v-if="item.isShow !== 8 && item.type == 'meTarget'"
              @click="to(item)"
            >
              <div
                v-if="user.role == 5 && item.type == 'meTarget'"
                @click="to(item)"
              >
                <div class="d-flex align-items-center">
                  <div class="icon mr-1">
                    <span
                      class="iconfont icon-line-084 text-white"
                      style="display: inline-block; transform: scale(0.5)"
                    ></span>
                  </div>
                  <div class="option-title">{{ item.title }}</div>
                </div>

                <div class="pt-2">
                  <div>
                    <span class="fs-10">{{ item.label2 }}</span>
                    <span class="option-count text-primary pr-1">{{
                      item.target_value > 0 ? item.target_value : "未设定"
                    }}</span>
                    <span v-if="item.target_value > 0" class="fs-10">{{
                      item.unit
                    }}</span>
                  </div>

                  <div class="d-flex align-items-center">
                    <span class="fs-10">{{ item.label1 }}</span>
                    <div>
                      <span
                        class="option-count text-primary pr-1"
                        style="font-weight: 500"
                        >{{ item.count }}</span
                      >
                      <span class="fs-10">{{ item.unit }}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <el-progress
                    :text-inside="true"
                    :stroke-width="16"
                    :format="fromat(item.percentage)"
                    :percentage="item.percentage > 100 ? 100 : item.percentage"
                  ></el-progress>
                </div>
              </div>
            </div>
          </el-col>
        </el-card>
      </el-row>
    </div>

    <!-- <icon-menu></icon-menu> -->
    <el-row :gutter="24" type="flex" class="pt-3 flex-wrap">
      <el-col :md="12" class="pt-3">
        <span class="font-weight-bold table-title">重点机会</span>
        <el-card class="card-1">
          <router-link :to="`followUp/list`">
            <div class="more">
              <div class="pt-1">查看更多></div>
            </div>
          </router-link>
          <table class="w-100 mt-2" v-if="followupCustomers.length > 0">
            <thead>
              <tr class="th-title">
                <th>序号</th>
                <th>客户名称</th>
                <th class="">距上次跟进天数</th>
                <th>跟进内容</th>
                <th>负责人</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in followupCustomers"
                :key="index"
                @click="goCustomer(item)"
              >
                <td class="fs-8 text-info">
                  <div style="width: 30px">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="fs-10">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    slot="reference"
                  >
                    <div class="text-truncate cursor-pointer">
                      {{ item.name }}
                    </div>
                    <div slot="reference">
                      <div
                        class="fs-10 text-truncate cursor-pointer text-primary name-width"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </el-popover>
                </td>
                <td class="fs-10">
                  <div class="day-width">
                    {{ item.days_keyPoint }}
                  </div>
                </td>
                <td class="fs-10">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    slot="reference"
                  >
                    <div style="max-width: 400px">
                      {{ item.history[historyIndex(item.history)].content }}
                    </div>
                    <div
                      slot="reference"
                      class="text-truncate-3 cursor-pointer history-width"
                    >
                      {{ item.history[historyIndex(item.history)].content }}
                    </div>
                  </el-popover>
                </td>
                <td class="fs-10">
                  <div class="user-w">
                    {{ (item.user || []).map((v) => v.realname).join(",") }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col>

      <el-col :md="12" class="pt-3">
        <span class="font-weight-bold table-title">每日提醒</span>
        <el-card class="card-1">
          <router-link :to="`schedules/list?more=more&new=true`">
            <div class="more">
              <div class="pt-1">查看更多></div>
            </div>
          </router-link>
          <table
            align="center"
            class="w-100 mt-2"
            v-if="dailyReminder.length > 0"
          >
            <thead>
              <tr class="th-title">
                <th>序号</th>
                <th>上课日期</th>
                <th>讲师</th>
                <th>课程名称</th>
                <th>培训机构</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in dailyReminder"
                :key="index"
                @click="goSchedule(item)"
              >
                <td class="fs-8 text-info">
                  <div style="width: 30px">
                    {{ index + 1 }}
                  </div>
                </td>
                <td class="fs-10">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    slot="reference"
                  >
                    <div style="max-width: 400px">
                      {{ cur_time(item) | formatDateH }}
                    </div>
                    <div slot="reference">
                      <div
                        class="text-truncate cursor-pointer"
                        style="width: 60px"
                      >
                        {{ cur_time(item) | formatDateH }}
                      </div>
                    </div>
                  </el-popover>
                </td>
                <td class="fs-10">
                  <div style="width: 40px">
                    {{ _.get(item, "teacher.name", "") }}
                  </div>
                </td>
                <td class="fs-10">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    slot="reference"
                  >
                    <div>
                      {{ item.post }}
                    </div>
                    <div
                      slot="reference"
                      class="text-truncate cursor-pointer post-width"
                    >
                      {{ item.post }}
                    </div>
                  </el-popover>
                </td>
                <td class="fs-10">
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    slot="reference"
                  >
                    <div>
                      {{ _.get(item, "customer.name", "") }}
                    </div>
                    <div
                      slot="reference"
                      class="text-truncate cursor-pointer name-width"
                    >
                      {{ _.get(item, "customer.name", "") }}
                    </div>
                  </el-popover>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col>
      <!-- <el-col :span="12" class="d-none">
        <span class="font-weight-bold">客户排行榜（前五）</span>
        <div class="box-shadow teacher-card">
          <table class="w-100 mt-2 text-center" v-if="costomersRank.length > 0">
            <thead>
              <tr>
                <th>排名</th>
                <th>客户</th>
                <th>所属城市</th>
                <th>跟进人</th>
                <th>订单金额</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in costomersRank" :key="index">
                <td class="fs-6 text-info">{{ index + 1 }}</td>
                <td class="fs-9">
                  {{ item.customers && item.customers.name }}
                </td>
                <td class="fs-9">
                  {{item.customers.region && item.customers.region.join("-")}}
                </td>
                <td class="fs-9">{{ item.users && item.users.realname }}</td>
                <td class="text-danger fs-7">￥{{ item.sum }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </div>
      </el-col> -->
    </el-row>

    <el-row :gutter="24" type="flex" class="pt-3 flex-wrap">
      <!-- <el-col :md="12" class="pt-3">
        <div class="implemented d-flex flex-wrap">
          <div
            class="font-weight-bold table-title"
            style="width: 130px"
            :class="{ 'w-100': !IsPC }"
          >
            已实施排行
          </div>
          <div class="daterange" :class="{ 'w-100': !IsPC }">
            <div>
              <el-date-picker
                size="mini"
                v-model="dates"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 200px !important"
              >
              </el-date-picker>
              <el-button class="ml-2" type="success" plain @click="screen"
                >筛选</el-button
              >
              <el-button
                type="success"
                class="mr-2"
                plain
                @click="
                  exportCustomData(
                    implementedRank,
                    implementedHeaders,
                    '已实施排行.xlsx'
                  )
                "
                >导出</el-button
              >
            </div>
            <el-radio-group
              v-model="implementRanking"
              size="mini"
              class="d-flex"
            >
              <el-radio-button label="month">本月排名</el-radio-button>
              <el-radio-button label="year">全年排名</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-card class="card-2">
          <table
            class="w-100 mt-2 text-center"
            v-if="implementedRank.length > 0"
          >
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>头像</th>
                <th>真实姓名</th>
                <th>已实施额/万</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="(item, index) in implementedRank"
                :key="item._id"
                @click="toList(item, 'implemented_rank')"
              >
                <td class="fs-8 text-info">{{ index + 1 }}</td>
                <td class="field">
                  <el-image
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    :src="item.avatar"
                    fit="cover"
                  >
                  </el-image>
                </td>
                <td class="fs-10">{{ item.realname }}</td>
                <td class="text-danger fs-8 text-bold">￥{{ item.amount }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col> -->
      <el-col :md="12" class="pt-3">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap h-100"
        >
        <div
            class="font-weight-bold table-title mb-2"
            :class="{ 'w-100': !IsPC }"
          >
            通知列表
          </div>
        <Noticelist />
      </div>
      </el-col>
      <el-col :md="12" class="pt-3">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div
            class="font-weight-bold table-title mb-2"
            :class="{ 'w-100': !IsPC }"
          >
            经纪人排行榜
          </div>
          <div class="ml-auto mb-2">
            <el-date-picker
              size="mini"
              v-model="saleRank_dates"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 200px !important"
            >
            </el-date-picker>
            <el-button class="ml-2" type="success" plain @click="handlerRank"
              >筛选</el-button
            >
          </div>
          <div v-if="IsPC" class="mb-2 ml-auto mx-2">
            <el-button type="success" plain @click="exportData"
              >导出数据</el-button
            >
          </div>
          <el-button
            :type="isGroupMode ? 'primary' : ''"
            class="mb-2 mr-2"
            @click="setGroupMode"
            >小组模式</el-button
          >
          <el-radio-group v-model="ranking" size="mini" @change="setRanking">
            <el-radio-button label="week">本周排名</el-radio-button>
            <el-radio-button label="month">本月排名</el-radio-button>
            <el-radio-button label="year">全年排名</el-radio-button>
          </el-radio-group>

          <el-radio-group v-model="implementRanking" size="mini" @change="setimplementRanking" v-if="!isGroupMode" >
            <el-radio-button label="year">本年已实施</el-radio-button>
            <el-radio-button label="month">本月已实施</el-radio-button>
          </el-radio-group>
        </div>
        <el-card class="card-2" v-if="!isGroupMode">
          <table class="w-100 mt-2 text-center" v-if="saleRank.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>头像</th>
                <th>真实姓名</th>
                <th v-if="ranking_type==='sale_amount'">销售额/万</th>
                <th v-if="ranking_type==='implement_amount'">已实施额/万</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="(item, index) in saleRank"
                :key="item._id"
                @click="toList(item, 'sale_rank')"
              >
                <td class="fs-7 text-info">{{ index + 1 }}</td>
                <td class="field">
                  <el-image
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    :src="item.user.avatar"
                    fit="cover"
                  >
                  </el-image>
                </td>
                <td class="fs-10">{{ item.user.realname }}</td>
                <td class="text-danger fs-8 text-bold">
                  ￥{{ (item.amount / 10000).toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>

        <el-card class="card-2" v-else>
          <table class="w-100 mt-2 text-center" v-if="groupRanks.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>头像</th>
                <th>部门</th>
                <th>销售额/万</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in groupRanks" :key="item.group_name">
              <tr
                class="cursor-pointer"
                :key="item._id"
                @click="expandItem(item)"
              >
                <td class="fs-7 text-info">{{ index + 1 }}</td>
                <td class="field">
                  <el-avatar
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    fit="cover"
                  >
                    {{ item.group_name[3] || "" }}
                  </el-avatar>
                </td>
                <td class="fs-10">
                  {{ item.group_name }}
                </td>
                <td class="text-danger fs-12 text-bold">
                  ￥{{ item.amount ? (item.amount / 10000).toFixed(2) : 0 }}
                </td>
                <td>
                  <el-button
                    icon="el-icon-arrow-up"
                    v-if="item.show"
                    circle
                  ></el-button>
                  <el-button
                    icon="el-icon-arrow-down"
                    v-else
                    circle
                  ></el-button>
                </td>
              </tr>

              <tr
                v-show="item.show"
                v-for="(user, ui) in item.children"
                :key="ui"
              >
                <td class="fs-8 pl-4 text-info text-danger">{{ ui + 1 }}</td>
                <td class="field">
                  <el-image
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    :src="user.avatar"
                    fit="cover"
                  >
                  </el-image>
                </td>
                <td class="fs-10">
                  {{ user.realname }} {{ user.isleader ? " (组长) " : "" }}
                </td>
                <td class="text-danger fs-8 text-bold">
                  ￥{{ user.amount ? (user.amount / 10000).toFixed(2) : 0 }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col>
      <!-- <el-col :md="12" class="pt-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-weight-bold table-title mb-2">小组排行榜</div>
          <div v-if="IsPC" class="mb-2">
            <el-button type="success" plain @click="exportGroupRankData"
              >导出数据</el-button
            >
          </div>

          <el-radio-group v-model="groupranking" size="mini">
            <el-radio-button label="week">本周排名</el-radio-button>
            <el-radio-button label="month">本月排名</el-radio-button>
            <el-radio-button label="year">全年排名</el-radio-button>
          </el-radio-group>
        </div>
        <el-card class="card-2">
          <table class="w-100 mt-2 text-center" v-if="groupRanks.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>头像</th>
                <th>部门</th>
                <th>销售额/万</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in groupRanks" :key="item.group_name">
              <tr
                class="cursor-pointer"
                :key="item._id"
                @click="expandItem(item)"
              >
                <td class="fs-7 text-info">{{ index + 1 }}</td>
                <td class="field">
                  <el-avatar
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    fit="cover"
                  >
                    {{ item.group_name[3] || "" }}
                  </el-avatar>
                </td>
                <td class="fs-10">
                  {{ item.group_name }}
                </td>
                <td class="text-danger fs-12 text-bold">
                  ￥{{ item.amount ? (item.amount / 10000).toFixed(2) : 0 }}
                </td>
              </tr>

              <tr
                v-show="item.show"
                v-for="(user, ui) in item.children"
                :key="ui"
              >
                <td class="fs-8 pl-4 text-info text-danger">{{ ui + 1 }}</td>
                <td class="field">
                  <el-image
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    :src="user.avatar"
                    fit="cover"
                  >
                  </el-image>
                </td>
                <td class="fs-10">
                  {{ user.realname }} {{ user.isleader ? " (组长) " : "" }}
                </td>
                <td class="text-danger fs-8 text-bold">
                  ￥{{ user.amount ? (user.amount / 10000).toFixed(2) : 0 }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col> -->
      <!-- <el-col :md="12" class="pt-3">
        <span class="font-weight-bold table-title">本月业绩目标</span>
        <el-card class="mt-2 card-2">
          <div>
            <div class="d-flex justify-content-between">
              <div>
                <el-popover
                  width="500"
                  placement="right-start"
                  v-model="visible"
                  trigger="click"
                  v-if="user.role == 0"
                >
                  <div class="d-flex py-3">
                    <span v-if="user.role == 0"> </span>
                    <div v-if="user.target_value">
                      {{ (user.target_value / 10000).toFixed(2) }}万元
                    </div>
                    <div v-else>未设定</div>
                  </div>

                  <div class="d-flex align-items-center">
                    <div style="width: 160px">公司：</div>
                    <el-input
                      type="number"
                      placeholder="请设置本月业绩目标"
                      v-model="user.target_value"
                    >
                    </el-input>
                    <span>(元)</span>
                  </div>
                  <div
                    class="d-flex align-items-center my-2"
                    v-for="item in users"
                    :key="item._id"
                  >
                    <div style="width: 160px">{{ item.group_name }}：</div>
                    <el-input
                      type="number"
                      placeholder="请设置本月业绩目标"
                      v-model="item.target_value"
                    >
                    </el-input>
                    <span>(元)</span>
                  </div>
                  <div class="text-right pt-3">
                    <el-button
                      class="mr-3"
                      size="small"
                      type="text"
                      @click="cancel"
                      >取消</el-button
                    >
                    <el-button type="primary" size="small" @click="save"
                      >保存</el-button
                    >
                  </div>
                  <div
                    slot="reference"
                    class="cursor-pointer d-flex align-items-center"
                  >
                    <div>
                      <el-button type="primary" v-if="user.role == 0">
                        设定本月目标</el-button
                      >
                    </div>
                  </div>
                </el-popover>
              </div>
              <div class="d-flex">
                <div class="pr-5">
                  <div class="fs-10">
                    <span class="text-primary fs-8 pr-1">{{ salesVolume }}</span
                    >万元
                  </div>
                  <div v-if="user.role == 5" class="fs-10">小组已完成值</div>
                  <div v-else class="fs-10">已完成值</div>
                </div>
                <div>
                  <div class="fs-10" v-if="user.role == 5 || user.role == 0">
                    <div>
                      <span class="text-primary fs-8 pr-1">{{
                        user.target_value
                          ? (user.target_value / 10000).toFixed(2)
                          : "未设定"
                      }}</span
                      >万元
                    </div>
                    <div class="fs-10">
                      {{ user.role == 0 ? "公司目标值" : "小组目标值" }}
                    </div>
                  </div>
                  <div class="fs-10" v-else>
                    <span class="text-primary fs-8 pr-1"
                      >{{
                        user.agentTarget_value
                          ? (user.agentTarget_value / 10000).toFixed(2)
                          : "未设定"
                      }} </span
                    >万元
                    <div class="fs-10">目标值</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-3">业绩完成率</div>
            <div ref="chart1" style="height: 300px"></div>
          </div>
        </el-card>
      </el-col> -->
      <!-- <el-col :md="12" class="pt-3">
        <span class="font-weight-bold table-title">财务模块</span>
        <el-card class="mt-2 teacher-card">
          <div @click="dialogGroup">
            <avue-data-display :option="customerOption"></avue-data-display>
          </div>
        </el-card>
      </el-col> -->
      <!-- <div class="bar-chart mt-3" style="width: 48%">
        <span class="pl-1 font-weight-bold">订单月份总额</span>
        <avue-echart-bar    
          ref="echart"
          width="100%"
          :option="config"
          :data="barData"
        ></avue-echart-bar>
      </div> -->
    </el-row>

    <el-row :gutter="24" type="flex" class="pt-3 flex-wrap">
      <!-- <el-col :md="12" class="pt-3">
        <div class="text-right year mb-2" v-if="implementRanking == 'year'">
          <span class="demonstration mr-2 fs-9">选择年份</span>
          <el-date-picker
            align="right"
            type="year"
            v-model="implementRankingYear"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>

        <div class="implemented">
          <div class="font-weight-bold table-title mb-2" style="width: 130px">
            老师排课金额
          </div>
          
        </div>
        <el-card class="card-2">
          <table class="w-100 mt-2 text-center" v-if="teacherRank.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>讲师姓名</th>
                <th>金额/万</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="(item, index) in teacherRank"
                :key="item._id"
                @click="toList(item, 'teacherRank')"
              >
                <td class="fs-8 text-info">{{ index + 1 }}</td>
                <td class="fs-10">{{ item.name }}</td>
                <td class="text-danger fs-8">￥{{ item.amount }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col> -->
      <!-- <el-col :md="12" class="pt-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-weight-bold table-title mb-2">
            讲师排行榜
          </div>
          <div v-if="IsPC" class="d-flex">
            <div class="mr-2" v-if="dataDate.way == 2">
              <el-button type="success" plain @click="exportTeacherData"
                >导出数据</el-button
              >
            </div>
            <div class="mr-2" v-if="dataDate.way == 0 || dataDate.way == 1">
              <el-button type="success" plain @click="exportTeacherAllData"
                >导出年度数据</el-button
              >
            </div>
            <el-radio-group v-model="dataDate.way" size="mini">
              <el-radio-button :label="0">线上</el-radio-button>
              <el-radio-button :label="1">线下</el-radio-button>
              <el-radio-button :label="2">排课金额</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-card class="card-2" v-if="dataDate.way !== 2">
          <table class="w-100 mt-2 text-center" v-if="wayRank.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>讲师姓名</th>
                <th>年度目标</th>
                <th>排课天数</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="(item, index) in wayRank"
                :key="item._id"
              >
                <td class="fs-7 text-info">{{ index + 1 }}</td>
                <td class="fs-10">{{ item.name }}</td>
                <td class="fs-10">{{ item.year_target || 0 }}</td>
                <td v-if="dataDate.way == 1" class="text-danger fs-8">
                  {{ item.fate }}天
                </td>
                <td v-else class="text-danger fs-8">{{ item.hour }}小时</td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>

        <el-card class="card-2" v-else>
          <table class="w-100 mt-2 text-center" v-if="teacherRank.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>讲师姓名</th>
                <th>销售金额/万</th>
                <th>实施金额/万</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="(item, index) in teacherRank"
                :key="item._id"
                @click="toList(item, 'teacherRank')"
              >
                <td class="fs-8 text-info">{{ index + 1 }}</td>
                <td class="fs-10">{{ item.name }}</td>
                <td class="text-danger fs-8">
                  ￥{{ (item.amount / 10000).toFixed(2) }}
                </td>
                <td class="text-danger fs-8">
                  ￥{{ (item.confirm_amount / 10000).toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col> -->

      <el-col :md="24" class="pt-3">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div
            class="font-weight-bold table-title mb-2"
            :class="{ 'w-100': !IsPC }"
          >
            讲师排行榜
          </div>
          <div v-if="IsPC" class="d-flex">
            <div class="mr-2" v-if="dataDate.way == 2">
              <el-button type="success" plain @click="exportTeacherData"
                >导出数据</el-button
              >
            </div>
            <el-date-picker
              class="mr-2"
              v-model="wayrankYear"
              :clearable="true"
              type="year"
              @change="fetchTeacherDay"
              placeholder="选择年"
            >
            </el-date-picker>
            <div class="mr-2">
              <el-button type="success" plain @click="exportTeacherAllData"
                >导出年度数据</el-button
              >
            </div>
            <el-radio-group v-model="dataDate.way" size="mini">
              <el-radio-button :label="0">线上</el-radio-button>
              <el-radio-button :label="1">线下</el-radio-button>
              <el-radio-button :label="2">排课金额</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-card class="card-2 way_rank" v-loading="wayRankLoading">
          <table
            class="w-100 mt-2 text-center"
            v-if="wayRank.length > 0"
            height="100%"
          >
            <thead class="way_title">
              <tr class="th-title">
                <th class="" v-for="item in wayRnakHeaders_arr" :key="item.key">
                  <div
                    class="w-100 h-100 d-flex align-items-center justify-content-center text-truncate"
                    :class="{
                      'fs-9': IsPC,
                      'm-fs-10': !IsPC,
                    }"
                  >
                    <span> {{ item.name }}</span>

                    <sort
                      :value="waySort[item.key]"
                      @update:value="(val) => setWaySort(val, item.key)"
                      v-if="item.sort && IsPC"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointe r"
                v-for="(item, index) in wayRank"
                :key="item._id"
              >
                <td v-for="(head, hi) in wayRnakHeaders_arr" :key="hi">
                  <span
                    v-if="head.key === 'index'"
                    :class="{
                      'm-fs-10': !IsPC,
                      'fs-9': IsPC,
                    }"
                    >{{ index + 1 }}</span
                  >
                  <span
                    v-else-if="isAmountKey(head.key)"
                   
                    :class="{
                      'm-fs-10': !IsPC,
                      'fs-9': IsPC,
                    }"
                  >
                    ￥{{
                      (item[head.key] && (item[head.key] / 10000).toFixed(2)) ||
                      0
                    }}
                  </span>
                  <span
                    v-else-if="head.key==='course_target'"
                  
                    :class="{
                      'm-fs-10': !IsPC,
                      'fs-9': IsPC,
                    }"
                  >
                  {{ item.course_target }} 天
                  </span>
                  <span
                    v-else-if="isDaysKey(head.key)"
                    class="text-truncate"
                    :class="{
                      'm-fs-10': !IsPC,
                      'fs-9': IsPC,
                    }"
                  >
                    {{
                      (item && item[head.key] && item[head.key].toFixed(2)) ||
                      0
                    }}天
                  </span>
                  <span
                    v-else
                    :class="{
                      'm-fs-10': !IsPC,
                      'fs-9': IsPC,
                    }"
                    >{{ item[head.key] }}</span
                  >
                </td>
                <!-- <td v-if="dataDate.way == 1" class="text-danger fs-8">
                  {{ item.fate }}天
                </td>
                <td v-else class="text-danger fs-8">{{ item.hour }}小时</td> -->
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col>

      <!-- 经纪人 -->

      <el-col :md="12" class="pt-3" v-if="user.role == 1">
        <!-- <div class="text-right year mb-2" v-if="ranking == 'year'">
          <span class="demonstration mr-2 fs-9">选择年份</span>
          <el-date-picker
            align="right"
            type="year"
            v-model="saleRankYear"
            placeholder="选择年"
          >
          </el-date-picker>
        </div> -->
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-weight-bold table-title mb-2">未回款机构排行榜</div>
          <!-- <div v-if="IsPC" class="d-flex">
            <div class="mr-2" v-if="dataDate.way == 2">
              <el-button type="success" plain @click="exportTeacherData"
                >导出数据</el-button
              >
            </div>
            <el-radio-group v-model="dataDate.way" size="mini">
              <el-radio-button :label="0">线上</el-radio-button>
              <el-radio-button :label="1">线下</el-radio-button>
              <el-radio-button :label="2">排课金额</el-radio-button>
            </el-radio-group>
          </div> -->
        </div>
        <el-card class="card-2">
          <table class="w-100 mt-2 text-center" v-if="backRank.length > 0">
            <thead>
              <tr class="th-title">
                <th>机构名称</th>
                <th>合作金额</th>
                <th>未回款金额</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="item in backRank"
                :key="item._id"
              >
                <td class="fs-10">{{ item.name }}</td>
                <td class="fs-10">
                  {{ $filters.formatMoneyW(item.order_amount) }}
                </td>
                <td class="text-danger fs-8">
                  ￥ {{ $filters.formatMoneyW(item.noback) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col>
      <!-- 管理员 -->
      <!-- <el-col :md="12" class="pt-3" v-if="user.role == 0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="font-weight-bold table-title mb-2">
            经纪人未回款排行榜
          </div>
        </div>
        <el-card class="card-2">
          <table class="w-100 mt-2 text-center" v-if="backRank.length > 0">
            <thead>
              <tr class="th-title">
                <th>排名</th>
                <th>头像</th>
                <th>名字</th>
                <th>金额</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="cursor-pointer"
                v-for="(item, index) in backRank"
                :key="item._id"
              >
                <td class="fs-8 text-info">{{ index + 1 }}</td>
                <td class="field">
                  <el-image
                    class="rounded-circle"
                    style="width: 40px; height: 40px"
                    :src="item.avatar"
                    fit="cover"
                  >
                  </el-image>
                </td>
                <td class="fs-10">{{ item.name }}</td>
                <td class="text-danger fs-8">
                  ￥ {{ $filters.formatMoneyW(item.no_back_amount) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else
            class="d-flex justify-content-center align-items-center h-100"
          >
            暂无数据
          </div>
        </el-card>
      </el-col> -->
    </el-row>

    <!-- <el-dialog width="60%" title="回款信息" :visible.sync="dialogTableVisible">
      <table
        style="width: "
        class="group-table"
        align="center"
        cellpadding="10"
      >
        <thead>
          <tr border="1">
            <th>经纪人部门</th>
            <th>已回款(万元)</th>
            <th>未回款(万元)</th>
            <th>已开票未回款(万元)</th>
            <th>未开票未回款(万元)</th>
            <th>3个月未回款(万元)</th>
          </tr>
        </thead>
        <tbody
          class="border-bottom border-left border-right"
          v-for="item in this.groups"
          :key="item._id"
        >
          <tr class="font-weight-bold">
            <td class="pl-3 py-2 pb-3">{{ item.group_name }}</td>
            <td align="center" class="pb-2 cursor-pointer">
              {{ groupCollection(_.cloneDeep(item), "received") }}
            </td>
            <td align="center" class="pb-2 cursor-pointer">
              {{ groupCollection(_.cloneDeep(item), "remain") }}
            </td>
            <td align="center" class="pb-2 cursor-pointer">
              {{ groupCollection(_.cloneDeep(item), "billRemain") }}
            </td>
            <td align="center" class="pb-2 cursor-pointer">
              {{ groupCollection(_.cloneDeep(item), "noBillRemain") }}
            </td>
              <td align="center" class="pb-2 cursor-pointer">
              {{ groupCollection(_.cloneDeep(item), "subremain") }}
            </td>
          </tr>

          <tr>
            <td align="left" class="pb-2 pl-4">{{ item.realname }}（组长）</td>
            <td
              align="center"
              class="pb-2 cursor-pointer"
              @click="getReceived(item._id)"
            >
              {{ ((item.received || 0) / 10000).toFixed(2) }}
            </td>
            <td
              align="center"
              class="pb-2 cursor-pointer"
              @click="getRemain(item._id)"
            >
              {{ ((item.remain || 0) / 10000).toFixed(2) }}
            </td>
            <td
              @click="getBillRemain(item._id)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((item.billRemain || 0) / 10000).toFixed(2) }}
            </td>
            <td
              @click="getnoBillRemain(item._id)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((item.noBillRemain || 0) / 10000).toFixed(2) }}
            </td>
             <td
              @click="getnoBillRemain(item._id)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((item.subremain || 0) / 10000).toFixed(2) }}
            </td>
          </tr>
          <tr v-for="user in item.userInfo" :key="user._id">
            <td align="left" class="pb-2 pl-4">{{ user.realname }}</td>
            <td
              align="center"
              class="pb-2 cursor-pointer"
              @click="getReceived(user.childId)"
            >
              {{ ((user.received || 0) / 10000).toFixed(2) }}
            </td>
            <td
              @click="getRemain(user.childId)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((user.remain || 0) / 10000).toFixed(2) }}
            </td>
            <td
              @click="getBillRemain(user.childId)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((user.billRemain || 0) / 10000).toFixed(2) }}
            </td>
            <td
              @click="getnoBillRemain(user.childId)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((user.noBillRemain || 0) / 10000).toFixed(2) }}
            </td>
             <td
              @click="getnoBillRemain(user.childId)"
              align="center"
              class="pb-2 cursor-pointer"
            >
              {{ ((user.subremain || 0) / 10000).toFixed(2) }}
            </td>
          </tr>
        </tbody>
        <tbody class="pt-3">
          <tr class="mt-3 font-weight-bold">
            <td class="pl-5">总计:</td>
            <td align="center">{{ groupTotal.received }}</td>
            <td align="center">{{ groupTotal.remain }}</td>
            <td align="center">{{ groupTotal.billRemain }}</td>
            <td align="center">{{ groupTotal.noBillRemain }}</td>
            <td align="center">{{ groupTotal.subremain }}</td>
          </tr>
        </tbody>
      </table>
    </el-dialog> -->

    <el-dialog
      :title="
        exportTeacherType === 'course' ? '导出讲师排课数据' : '导出讲师年度数据'
      "
      :visible.sync="dialogTeacherExport"
      width="40%"
      :before-close="handleClose"
    >
      <div>
        <!-- <el-checkbox v-model="all" @change="changeAll">{{
          all ? "取消" : "全选"
        }}</el-checkbox> -->
      </div>
      <avue-form
        v-model="exportTeacherForm"
        ref="export_teacher_form"
        :option="exportTeacher"
        @submit="exportTeacherSubmit"
      >
        <template #teachers="{ disabled }">
          <div>
            <MultipleSelect
              :disabled="disabled"
              filterable
              :selectOptions="teachers"
              v-model="exportTeacherForm.teachers"
              ref="multipleSelect"
            />
          </div>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      title="设置本月业绩目标"
    >
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="公司" v-if="user.role == 0">
          <div class="d-flex align-items-center pb-1">
            <div class="mr-2" style="width: 80px">公司</div>
            <div class="w-75">
              <el-input
                type="number"
                placeholder="请设置公司本月业绩目标"
                :value="formatMoneyStr(user.target_value)"
                @input="(val) => setItemGoal(val, user, 'target_value')"
              >
              </el-input>
            </div>
            <span class="ml-2 text-nowrap">(万元)</span>
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-for="(item, ti) in users"
          :key="item._id"
          :label="item.group_name"
        >
          <div>
            <div class="d-flex align-items-center pb-1">
              <div class="mr-2" style="width: 80px">
                {{ item.group_name }}
              </div>
              <div class="w-75">
                <el-input
                  type="number"
                  placeholder="请设置部门本月业绩目标"
                  :value="formatMoneyStr(item.target_value)"
                  @input="(val) => setItemGoal(val, item, 'target_value', ti)"
                >
                </el-input>
              </div>
              <span class="ml-2 text-nowrap">(万元)</span>
            </div>
            <div class="d-flex align-items-center py-1">
              <div class="mr-2" style="width: 80px">
                {{ item.realname }}
              </div>
              <div class="w-75">
                <el-input
                  type="number"
                  placeholder="请设置个人本月业绩目标"
                  :value="formatMoneyStr(item.agentTarget_value)"
                  @input="(val) => setItemGoal(val, item, 'agentTarget_value')"
                >
                </el-input>
              </div>
              <span class="ml-2 text-nowrap">(万元)</span>
            </div>
            <div
              v-for="(user, ui) in item.userInfo"
              :key="user._id"
              class="d-flex align-items-center py-1"
            >
              <div class="mr-2" style="width: 80px">
                {{ user.realname }}
              </div>
              <div class="w-75">
                <el-input
                  type="number"
                  placeholder="请设置个人本月业绩目标"
                  :value="formatMoneyStr(user.agentTarget_value)"
                  @input="
                    (val) => setItemUserGoal(val, item, ui, 'agentTarget_value')
                  "
                >
                </el-input>
              </div>
              <span class="ml-2 text-nowrap">(万元)</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="groupDayDialog"
      width="30%"
      title="组员本日预约额"
    >
      <el-table
        class="mt-3"
        :data="dayUserAmount"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column prop="realname" label="姓名"> </el-table-column>
        <el-table-column prop="total" label="预约额">
          <template slot-scope="{ row }">
            {{ row.total || 0 }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="groupMonthDialog"
      width="30%"
      title="组员本月预约额"
    >
      <el-table
        class="mt-3"
        :data="monthUserAmount"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column prop="realname" label="姓名"> </el-table-column>
        <el-table-column prop="total" label="预约额">
          <template slot-scope="{ row }">
            {{ row.total || 0 }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="groupMonthDialogVisible"
      width="30%"
      :title="`本组${dayjs().month() + 1}月业绩完成率`"
    >
      <el-table
        class="mt-3"
        :data="monthUserInfo"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column prop="realname" label="姓名"> </el-table-column>
        <el-table-column prop="agentTarget_value" label="月目标">
          <template slot-scope="{ row }">
            {{ row.agentTarget_value || "未设定" }}
          </template>
        </el-table-column>
        <el-table-column prop="total" label="完成业绩">
          <template slot-scope="{ row }">
            {{ row.total || 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="teacher" label="完成率">
          <template slot-scope="{ row }">
            <el-progress
              :text-inside="true"
              :stroke-width="16"
              :format="
                fromat(
                  Number(((row.total / row.agentTarget_value) * 100).toFixed(1))
                )
              "
              :percentage="
                Number(((row.total / row.agentTarget_value) * 100).toFixed(1)) >
                100
                  ? 100
                  : Number(
                      ((row.total / row.agentTarget_value) * 100).toFixed(1)
                    ) || 0
              "
            ></el-progress>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="groupYearDialogVisible"
      width="30%"
      :title="`本组${dayjs().year()}年业绩完成率`"
    >
      <el-table
        class="mt-3"
        :data="yearUserInfo"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column prop="realname" label="姓名"> </el-table-column>
        <el-table-column prop="agentTarget_value" label="年目标">
          <template slot-scope="{ row }">
            {{ row.agent_annual_goal || "未设定" }}
          </template>
        </el-table-column>
        <el-table-column prop="total" label="完成业绩">
          <template slot-scope="{ row }">
            {{ row.total || 0 }}
          </template>
        </el-table-column>
        <el-table-column prop="teacher" label="完成率">
          <template slot-scope="{ row }">
            <el-progress
              :text-inside="true"
              :stroke-width="16"
              :format="
                fromat(
                  Number(((row.total / row.agent_annual_goal) * 100).toFixed(1))
                )
              "
              :percentage="
                Number(((row.total / row.agent_annual_goal) * 100).toFixed(1)) >
                100
                  ? 100
                  : Number(
                      ((row.total / row.agent_annual_goal) * 100).toFixed(1)
                    ) || 0
              "
            ></el-progress>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :visible.sync="yearDialogVisible"
      width="30%"
      title="设置本年业绩目标"
    >
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="公司" v-if="user.role == 0">
          <div class="d-flex align-items-center pb-1">
            <div class="mr-2" style="width: 80px">公司</div>
            <div class="w-75">
              <el-input
                type="number"
                placeholder="请设置公司本年业绩目标"
                :value="formatMoneyStr(user.group_annual_goal)"
                @input="(val) => setUserGoal(val)"
              >
              </el-input>
            </div>
            <span class="ml-2 text-nowrap">(万元)</span>
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-for="(item, ti) in users"
          :key="item._id"
          :label="item.group_name"
        >
          <div>
            <div class="d-flex align-items-center pb-1">
              <div class="mr-2" style="width: 80px">
                {{ item.group_name }}
              </div>
              <div class="w-75">
                <el-input
                  type="number"
                  placeholder="请设置部门本年业绩目标"
                  :value="formatMoneyStr(item.group_annual_goal)"
                  @input="
                    (val) => setItemGoal(val, item, 'group_annual_goal', ti)
                  "
                >
                </el-input>
              </div>
              <span class="ml-2 text-nowrap">(万元)</span>
            </div>
            <div class="d-flex align-items-center py-1">
              <div class="mr-2" style="width: 80px">
                {{ item.realname }}
              </div>
              <div class="w-75">
                <el-input
                  type="number"
                  placeholder="请设置个人本年业绩目标"
                  :value="formatMoneyStr(item.agent_annual_goal)"
                  @input="
                    (val) => setItemGoal(val, item, 'agent_annual_goal', ti)
                  "
                >
                </el-input>
              </div>
              <span class="ml-2 text-nowrap">(万元)</span>
            </div>
            <div
              v-for="(user, ui) in item.userInfo"
              :key="user._id"
              class="d-flex align-items-center py-1"
            >
              <div class="mr-2" style="width: 80px">
                {{ user.realname }}
              </div>
              <div class="w-75">
                <el-input
                  type="number"
                  placeholder="请设置个人本年业绩目标"
                  :value="formatMoneyStr(user.agent_annual_goal)"
                  @input="
                    (val) => setItemUserGoal(val, item, ui, 'agent_annual_goal')
                  "
                >
                </el-input>
              </div>
              <span class="ml-2 text-nowrap">(万元)</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="yearSave">保存</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog width="80%" :visible.sync="saleRankDialog">
      <avue-crud
        :data="saleRankData"
        :option="listOption"
        :page.sync="page"
        @on-load="changPage"
      >
        <template slot="teacher" slot-scope="scope">
          <div>{{ _.get(scope.row, "teacher.name") }}</div>
        </template>
        <template slot="customer" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'customer.name')"
            placement="top-start"
          >
            <div class="text-truncate cursor-pointer" style="max-width: 30vw">
              {{ _.get(scope.row, "customer.name") }}
            </div>
          </el-tooltip>
        </template>

        <template slot="city" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="
              Array.isArray(scope.row.city)
                ? _.get(scope.row, 'city').join('-')
                : _.get(scope.row, 'city')
            "
            placement="top-start"
          >
            <div>
              <div
                class="text-truncate cursor-pointer"
                style="max-width: 20vw"
                v-if="Array.isArray(scope.row.city)"
              >
                {{ _.get(scope.row, "city").join("-") }}
              </div>
              <div
                class="text-truncate cursor-pointer"
                style="max-width: 20vw"
                v-else
              >
                {{ _.get(scope.row, "city") }}
              </div>
            </div>
          </el-tooltip>
        </template>
        <template slot="post" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'post')"
            placement="top-start"
          >
            <div class="text-truncate cursor-pointer" style="max-width: 18vw">
              {{ _.get(scope.row, "post") }}
            </div>
          </el-tooltip>
        </template>
        <template #times="{ row }">
          <el-tooltip
            effect="dark"
            :content="
              _.get(row, 'times', [])
                .map((v) => $filters.formatDateT(v))
                .join('，')
            "
            placement="top-start"
          >
            <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

            <div
              class="cursor-pointer"
              :class="IsPC ? 'text-truncate1' : 'text-truncate'"
              style="max-width: 20vw"
            >
              <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

              <div
                class="times"
                v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)"
                :key="i"
              >
                <span :key="i">{{ $filters.formatDateT(item) }}</span>
                <!-- <br :key="`br-` + i" /> -->
              </div>
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope" slot="user">
          <div>{{ _.get(scope.row, "user.realname") }}</div>
        </template>
      </avue-crud>
    </el-dialog>

    <el-dialog width="80%" :visible.sync="teacherRankDialog">
      <avue-crud
        :data="teacherRankData"
        :option="listOption"
        :page.sync="teacherRankPage"
        @on-load="teacherRankChangPage"
      >
        <template slot="teacher" slot-scope="scope">
          <div>{{ _.get(scope.row, "teacher.name") }}</div>
        </template>
        <template slot="customer" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'customer.name')"
            placement="top-start"
          >
            <div class="text-truncate cursor-pointer" style="max-width: 30vw">
              {{ _.get(scope.row, "customer.name") }}
            </div>
          </el-tooltip>
        </template>

        <template slot="city" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="
              Array.isArray(scope.row.city)
                ? _.get(scope.row, 'city').join('-')
                : _.get(scope.row, 'city')
            "
            placement="top-start"
          >
            <div>
              <div
                class="text-truncate cursor-pointer"
                style="max-width: 20vw"
                v-if="Array.isArray(scope.row.city)"
              >
                {{ _.get(scope.row, "city").join("-") }}
              </div>
              <div
                class="text-truncate cursor-pointer"
                style="max-width: 20vw"
                v-else
              >
                {{ _.get(scope.row, "city") }}
              </div>
            </div>
          </el-tooltip>
        </template>
        <template slot="post" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'post')"
            placement="top-start"
          >
            <div class="text-truncate cursor-pointer" style="max-width: 18vw">
              {{ _.get(scope.row, "post") }}
            </div>
          </el-tooltip>
        </template>
        <template #times="{ row }">
          <el-tooltip
            effect="dark"
            :content="
              _.get(row, 'times', [])
                .map((v) => $filters.formatDateT(v))
                .join('，')
            "
            placement="top-start"
          >
            <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

            <div
              class="cursor-pointer"
              :class="IsPC ? 'text-truncate1' : 'text-truncate'"
              style="max-width: 20vw"
            >
              <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

              <div
                class="times"
                v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)"
                :key="i"
              >
                <span :key="i">{{ $filters.formatDateT(item) }}</span>
                <!-- <br :key="`br-` + i" /> -->
              </div>
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope" slot="user">
          <div>{{ _.get(scope.row, "user.realname") }}</div>
        </template>
      </avue-crud>
    </el-dialog>

    <el-dialog width="80%" :visible.sync="implementedAmountRankDialog">
      <avue-crud
        :data="implementedAmountRankData"
        :option="listOption"
        :page.sync="implementedPage"
        @on-load="rankChangPage"
      >
        <template slot="teacher" slot-scope="scope">
          <div>{{ _.get(scope.row, "teacher.name") }}</div>
        </template>
        <template slot="customer" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'customer.name')"
            placement="top-start"
          >
            <div class="text-truncate cursor-pointer" style="max-width: 30vw">
              {{ _.get(scope.row, "customer.name") }}
            </div>
          </el-tooltip>
        </template>

        <template slot="city" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="
              Array.isArray(scope.row.city)
                ? _.get(scope.row, 'city').join('-')
                : _.get(scope.row, 'city')
            "
            placement="top-start"
          >
            <div>
              <div
                class="text-truncate cursor-pointer"
                style="max-width: 20vw"
                v-if="Array.isArray(scope.row.city)"
              >
                {{ _.get(scope.row, "city").join("-") }}
              </div>
              <div
                class="text-truncate cursor-pointer"
                style="max-width: 20vw"
                v-else
              >
                {{ _.get(scope.row, "city") }}
              </div>
            </div>
          </el-tooltip>
        </template>
        <template slot="post" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="_.get(scope.row, 'post')"
            placement="top-start"
          >
            <div class="text-truncate cursor-pointer" style="max-width: 18vw">
              {{ _.get(scope.row, "post") }}
            </div>
          </el-tooltip>
        </template>
        <template #times="{ row }">
          <el-tooltip
            effect="dark"
            :content="
              _.get(row, 'times', [])
                .map((v) => $filters.formatDateT(v))
                .join('，')
            "
            placement="top-start"
          >
            <!-- <div v-for="(item, i) in _.get(row, 'times', [])" :key="i">
                  {{ $filters.formatDateT(item) }}
                </div>-->

            <div
              class="cursor-pointer"
              :class="IsPC ? 'text-truncate1' : 'text-truncate'"
              style="max-width: 20vw"
            >
              <!-- {{ scope.row.times && scope.row.times.join("<br>") | formatDateT
                  }}-->

              <div
                class="times"
                v-for="(item, i) in _.get(row, 'times', []).slice(0, 4)"
                :key="i"
              >
                <span :key="i">{{ $filters.formatDateT(item) }}</span>
                <!-- <br :key="`br-` + i" /> -->
              </div>
            </div>
          </el-tooltip>
        </template>
        <template slot-scope="scope" slot="user">
          <div>{{ _.get(scope.row, "user.realname") }}</div>
        </template>
      </avue-crud>
    </el-dialog>

    <el-dialog
      title="数据分析"
      :visible.sync="dataDialogVisible"
      width="80%"
      align="center"
    >
      <div class="text-right mb-3">
        <span class="mr-2 fs-9">当前数据年份</span>
        <el-date-picker
          style="width: 120px"
          v-model="dataQuery.year"
          type="year"
          :clearable="false"
          value-format="yyyy"
          placeholder="请选择年份"
        />
      </div>
      <div class="mb-4">
        <avue-data-panel :option="dataOption"></avue-data-panel>
      </div>
      <div class="d-flex">
        <div ref="chart1" style="height: 280px; width: 100%"></div>
        <div ref="chart2" style="height: 280px; width: 100%"></div>
      </div>

      <ScheduleChart :query="dataQuery" />

      <!-- <el-table
        class="mt-3"
        :data="customers"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column prop="_id.name" label="机构名称">
          <template slot-scope="{ row }">
              <span v-if="row.customer">{{ row.customer.name }}</span>
              <span v-else>客户已删除</span>
          </template>
        </el-table-column>
        <el-table-column prop="days" label="线下授课/天"> </el-table-column>
        <el-table-column prop="hour" label="线上授课/小时"> </el-table-column>
      </el-table> -->

      <!-- <div class="mt-3">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="customerQuery.page"
          layout="total, prev, pager, next"
          :total="customerPage.total"
        >
        </el-pagination>
      </div> -->
    </el-dialog>

    <el-dialog
      title="导出"
      :visible.sync="dialogExport"
      width="50%"
      :before-close="handleClose"
    >
      <avue-form
        v-model="exportForm"
        :option="exportOption"
        @submit="exportSubmit"
      ></avue-form>
    </el-dialog>
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import ScheduleChart from "../components/ScheduleChart.vue";

import { formatMoneyWNum } from "@/filters.js";
import Noticelist from "../components/noticelist.vue";
export default {
  data() {
    // const a = this.groupTotal("payment_received");
    return {
      teacherRankData: [],
      ranking_type:"sale_amount",
      exportTeacherType: "course",
      teachers: [],
      obj_user: null,
      obj_teacher: null,
      implementedAmountRankData: [],
      saleRankData: [],
      saleRankDialog: false,
      teacherRankDialog: false,
      implementedAmountRankDialog: false,
      monthSaleMoney: null,
      monthApplyMoney: null,
      dataOption: {},
      groupMonthDialogVisible: false,
      groupDayDialog: false,
      groupMonthDialog: false,
      dataDialogVisible: false,
      groupYearDialogVisible: false,
      monthUserInfo: [],
      yearUserInfo: [],
      monthUserAmount: [],
      dayUserAmount: [],
      dialogVisible: false,
      yearDialogVisible: false,
      loading: true,
      dialogTableVisible: true,
      isGroupMode: false,
      dataQuery: {
        year: new Date(),
        // limit: 10,
        // page: 1,
      },
      dataDate: {
        dataMonth: new Date(),
        way: 1,
      },
      date: new Date(),
      targetTip: false,
      saleRankYear: new Date().getFullYear().toString(),
      implementRankingYear: new Date().getFullYear().toString(),
      tabPosition: "left",
      users: [],
      groups: [],
      saleQuery: {
        limit: 10,
        page: 1,
      },
      implementedAmountQuery: {
        limit: 10,
        page: 1,
      },
      teacherAmountQuery: {
        limit: 10,
        page: 1,
      },
      page: {
        total: 0,
      },
      implementedPage: {
        total: 0,
      },
      teacherRankPage: {
        total: 0,
      },
      query: { limit: 0, where: { role: 5 } },
      visible: false,
      dates: null,
      saleRank_dates: null,
      exportForm: {},
      exportTeacherForm: {
        teachers: [],
      },
      dialogExport: false,
      dialogTeacherExport: false,
      exportType: "user",
      implementRanking: "year",
      groupRanks: [],
      ranking: "year",
      groupranking: "year",
      salesVolume: 0,
      targetData: {},
      test_amount: 10000000,
      // theme: "",
      followupCustomers: [],
      dailyReminder: [],
      performance_target: null,
      saleRank: [],
      wayRank: [],
      wayRankLoading: false,
      wayrankYear: dayjs().toDate(),
      wayRnakHeaders: [
        {
          name: "排名",
          sort: false,
          key: "index",
        },
        {
          name: "讲师姓名",
          sort: false,
          key: "name",
        },
        {
          name: "本年定课",
          sort: true,
          key: "course_days",
        },
        {
          name: "课量目标",
          sort: true,
          key: "course_target",
        },
        {
          name: "销售金额/万",
          sort: true,
          key: "order_amount",
        },
        {
          name: "实施金额/万",
          sort: true,
          key: "implement_amount",
        },
        {
          name: "已实施天数",
          sort: true,
          key: "implement_days",
        },
        {
          name: "待实施天数",
          sort: true,
          key: "un_implement_days",
        },
        {
          name: "预约天数",
          sort: true,
          key: "reserve_days",
        },
        {
          name: "已回款/万",
          sort: true,
          key: "received_amount",
        },
        {
          name: "未回款/万",
          sort: true,
          key: "remain_amount",
        },

        {
          name: "目标差",
          sort: true,
          key: "diff_target",
        },
      ],
      waySort: {},
      backRank: [],
      implementedRank: [],
      implementedHeaders: {
        realname: "真实姓名",
        amount: "金额/（万）",
      },
      teacherRank: [],
      teacherRankList: [],
      costomersRank: [],
      monthData: [],
      homeData: {},
      states: {},
      pieData: [],
      barData: {
        categories: [
          "1月",
          "2月",
          "3月",
          "4月",
          "5月",
          "6月",
          "7月",
          "8月",
          "9月",
          "10月",
          "11月",
          "12月",
        ],
        series: [
          {
            name: "订单总额",
            data: null,
          },
        ],
      },
      // customerOption2: {
      //   // received: {
      //   //   count: 0,
      //   //   title: "已回款(万元)",
      //   // },
      //   // remain: {
      //   //   count: 0,
      //   //   title: "未回款(万元)",
      //   // },
      //   billRemain: {
      //     count: 0,
      //     title: "已开票未回款(万元)",
      //   },
      //   noBillRemain: {
      //     count: 0,
      //     title: "未开票未回款(万元)",
      //   },
      // },
      gaugeConfig: null,
      option: {},
      export_teacher_form: "",
      all: false,
      exportTeacher: {
        translate: false,
        column: [
          {
            label: "选择导出讲师",
            prop: "teachers",
            type: "tree",
            props: {
              label: "name",
              value: "_id",
            },
            span: 24,
            multiple: true,
            dicUrl: `teachers/option`,
            all: true,
            // typeformat(item, label, value) {
            //   return `值:${item[label]}-名:${item[value]}`;
            // },
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择需要导出的讲师",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    listOption() {
      return {
        border: true,
        searchMenuSpan: 5,
        menuWidth: 160,
        index: true,
        align: "center",
        editBtn: false,
        addBtn: false,
        header: false,
        removeBtn: false,
        menu: false,
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "讲师",
            prop: "teacher",
            filterable: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            type: "select",
          },
          {
            label: "授课方式",
            prop: "way",
            type: "radio",
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择授课方式",
                trigger: "blur",
              },
            ],
            dicData: [
              {
                label: "线上",
                value: 0,
              },
              {
                label: "线下",
                value: 1,
              },
            ],
          },
          {
            label: "客户名称",
            filterable: true,
            prop: "customer",
            slot: true,
            props: {
              label: "name",
              value: "_id",
            },
            span: 8,
            width: "140px",
            // remote: true,
            type: "select",
          },

          {
            label: "上课日期",
            prop: "times",
            type: "dates",
            format: "yyyy-MM-dd",
            formatter: (row, value) => value && value.join("-"),
            slot: true,
            width: "106px",
            span: 16,
          },

          {
            label: "课程名称",
            prop: "post",
            span: 8,
            slot: true,
            width: "120px",
            rules: [
              {
                required: this.isRequired,
                message: "请输入课程名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "授课类别",
            prop: "category",
            props: {
              label: "label",
              value: "label",
            },
            type: "select",
            span: 8,
          },
          {
            label: "授课城市",
            slot: true,
            prop: "city",
            filterable: true,
            type: "cascader",
            props: {
              label: "value",
              value: "value",
            },
            span: 8,
            rules: [
              {
                required: true,
                message: "请选择授课城市",
                trigger: "blur",
              },
            ],
          },

          {
            label: "订单总额",
            prop: "order_amount",
            type: "number",
            span: 8,
          },
          {
            label: "负责人",
            prop: "user",
            slot: true,
            display: false,
          },
        ],
      };
    },
    formatMoneyStr() {
      return (value) => {
        return formatMoneyWNum(value);
      };
    },
    wayRnakHeaders_arr() {
      if (this.IsPC) {
        return this.wayRnakHeaders;
      } else {
        let showKeys = [
          "index",
          "name",
          "course_days",
          "order_amount",
          "implement_amount",
          "received_amount",
          "remain_amount",
          "diff_target",
        ];
        return this.wayRnakHeaders.filter((v) => showKeys.includes(v.key));
      }
    },
    isAmountKey() {
      return (key) => {
        let amountKeys = [
          "order_amount",
          "implement_amount",
          "received_amount",
          "remain_amount",
        ];
        return amountKeys.includes(key);
      };
    },
    isDaysKey() {
      return (key) => {
        let daysKey = [
          "course_days",
          "implement_days",
          "un_implement_days",
          "reserve_days",
          // "course_target",
          "diff_target",
        ];
        return daysKey.includes(key);
      };
    },
    showWayRankHeader() {
      return (key) => {
        if (this.IsPC) {
          return true;
        } else {
          console.log(this.IsPC);

          let showKeys = [
            "index",
            "name",
            "course_days",
            "order_amount",
            "implement_amount",
            "received_amount",
            "remain_amount",
            "diff_target",
          ];

          return showKeys.includes(key);
        }
      };
    },
    cur_time() {
      return (item) => {
        let times = item.times.filter((v) => dayjs(v).toDate() > new Date());
        return times.join(",");
      };
    },
    formatMoneyWNum() {
      return (value) => {
        return formatMoneyW(value);
      };
    },
    groupTotal() {
      let value = ["received", "remain", "billRemain", "noBillRemain"];
      const data = value.reduce((obj, curKey) => {
        const num = this.groups.reduce((amount, cur) => {
          return (amount += Number(
            this.groupCollection(_.cloneDeep(cur), curKey)
          ));
        }, 0);
        obj[curKey] = Number(num.toFixed(2)).toFixed(2);
        return obj;
      }, {});
      return data;
    },

    // customerOption() {
    //   return {
    //     span: 8,
    //     decimals: 2,
    //     data: Object.keys(this.customerOption2).map((key) => {
    //       return {
    //         title: this.customerOption2[key].title,
    //         count: this.customerOption2[key].count,
    //       };
    //     }),
    //   };
    // },
    exportOption() {
      const type = this.exportForm.type;
      return {
        translate: false,
        column: [
          {
            label: "导出方式",
            prop: "type",
            type: "select",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择导出类型",
                trigger: "blur",
              },
            ],
            dicData: [
              {
                label: "本周|本月|全年",
                value: 1,
              },
              {
                label: "选择日期范围",
                value: 2,
              },
            ],
          },
          {
            label: "导出字段",
            prop: "field",
            type: "select",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择导出字段",
                trigger: "blur",
              },
            ],
            dicData: [
              {
                label: "销售额",
                value: 1,
              },
              {
                label: "已实施额",
                value: 2,
              },
            ],
          },
          {
            label: "日期范围",
            prop: "daterange",
            type: "daterange",
            startPlaceholder: "选择导出的开始时间",
            endPlaceholder: "选择导出的结束时间",
            span: 24,
            valueFormat: "timestamp",
            display: type == 2,
            rules: [
              {
                required: true,
                message: "请选择导出的日期范围",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  components: {
    ScheduleChart,
    Noticelist
  },
  watch: {
    "dataQuery.year"() {
      this.fetchDataAnalysis();
      // setTimeout(() => {
      // }, 500);
      this.$message({
        message: `已为您切换至${this.dayjs(this.dataQuery.year).format(
          "YYYY"
        )}年数据`,
        type: "success",
        align: "center",
        center: true,
      });
    },
    "dataDate.way"() {
      this.fetchTeacherDay();
    },
    "dataDate.dataMonth"() {
      this.fetch();
      this.fetchImplementedAmount();
      this.fetchTeacherAmount();
      this.fetchTeacherDay();
      this.fetchSaleRank();
      this.fetchGroupRanks();
      this.$message({
        message: `已为您切换至${this.dayjs(this.dataDate.dataMonth).format(
          "YYYY-MM-DD"
        )}月数据`,
        type: "success",
        align: "center",
        center: true,
      });
    },
    ranking() {
      this.fetchSaleRank();
      this.fetchGroupRanks();
    },

    groupranking() {
      this.fetchGroupRanks();
    },
    implementRanking() {
      // this.fetchSaleRank();
    },
    // groupTotal(val) {
    //   Object.keys(val).forEach((key) => {
    //     this.customerOption2[key].count = val[key];
    //   });
    // },
  },
  methods: {
    handlerRank() {
      this.fetchSaleRank();
      this.fetchGroupRanks();
    },
    setWaySort(val, key) {
      this.waySort = {
        [key]: val,
      };
      if (this.wayRankLoading) {
        return false;
      }
      this.fetchTeacherDay();
    },
    changeAll(val) {
      if (val) {
        this.exportTeacherForm.teachers = this.teachers.map((v) => v.id);
      } else {
        this.exportTeacherForm.teachers = [];
      }
      // console.log(this.exportTeacherForm);
    },
    setGroupMode() {
      this.isGroupMode = !this.isGroupMode;

      console.log(this.isGroupMode);
    },
    expandItem(item) {
      this.$set(item, "show", !item.show);
    },
    setUserGoal(val) {
      this.$set(this.user, "group_annual_goal", val ? val * 10000 : 0);
    },
    setItemGoal(val, obj, key) {
      console.log({
        obj,
        key,
        val,
      });
      // obj[key] =val ? val * 10000 : 0;
      this.$set(obj, key, val ? val * 10000 : 0);

      if (key == "agentTarget_value") {
        const total = obj.userInfo.reduce((total, cur) => {
          return (total += cur[key] || 0);
        }, 0);

        const group_total = obj[key];
        let count = total + group_total;
        this.$set(obj, "target_value", count ? count : 0);
      }
      if (key == "agent_annual_goal") {
        const total = obj.userInfo.reduce((total, cur) => {
          return (total += cur[key] || 0);
        }, 0);

        const group_total = obj[key];
        let count = total + group_total;
        this.$set(obj, "group_annual_goal", count ? count : 0);
      }
    },
    setItemUserGoal(val, obj, ui, key) {
      let userInfo = obj.userInfo;
      let item = userInfo[ui];
      this.$set(item, key, val ? val * 10000 : 0);
      // console.log({
      //   val,
      //   item,
      //   ui,
      //   key,
      // });
      this.$set(userInfo, ui, item);
      this.$set(obj, "userInfo", userInfo);

      if (key == "agentTarget_value") {
        const total = obj.userInfo.reduce((total, cur) => {
          return (total += cur[key] || 0);
        }, 0);

        const group_total = obj[key];
        let count = total + group_total;
        this.$set(obj, "target_value", count ? count : 0);
      }
      if (key == "agent_annual_goal") {
        const total = obj.userInfo.reduce((total, cur) => {
          return (total += cur[key] || 0);
        }, 0);

        const group_total = obj[key];
        let count = total + group_total;
        this.$set(obj, "group_annual_goal", count ? count : 0);
      }
    },
    setItemUsersGoal(val, ti, key) {
      this.users[ti][key] == val ? val * 10000 : 0;
    },
    fromat(percentage) {
      return () => {
        return (percentage || 0) + "%";
      };
    },
    getReceived(id) {
      this.$router.push(
        `schedules/list?userId=${id}&payBack_type=received&new=true`
      );
    },
    getRemain(id) {
      this.$router.push(
        `schedules/list?userId=${id}&payBack_type=remain&new=true`
      );
    },

    getBillRemain(id) {
      this.$router.push(
        `schedules/list?userId=${id}&payBack_type=billRemain&new=true`
      );
    },
    getnoBillRemain(id) {
      this.$router.push(
        `schedules/list?userId=${id}&payBack_type=noBillRemain&new=true`
      );
    },
    // groupTotal(value) {
    //   console.log(this.groups);

    //   const groups = this.groups.map((v) => v[value]);
    //   let userInfo = [];
    //   this.groups.map((v) => {
    //     userInfo = v.userInfo.map((v) => v[value]);
    //   });
    //   const total = _.concat(groups, userInfo);
    //   return (eval(total.join("+")) / 10000).toFixed(2);
    // },
    dialogGroup() {
      this.dialogTableVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
      this.yearDialogVisible = false;
    },
    historyIndex(item) {
      const arr = item.findIndex((v) => {
        return v.followUp_type == "keyPoint";
      });

      return arr;
    },
    groupCollection(item, value) {
      const total =
        item &&
        item.userInfo &&
        _.concat(
          item.userInfo?.map((v) => {
            return v[value] || 0;
          }),
          [item[value] || 0]
        );
      return (eval(total.join("+")) / 10000).toFixed(2) || 0;
    },
    // groupUncollected(item) {
    //   const total = _.concat(
    //     item.userInfo.map((v) => v.uncollected_payment),
    //     [item.uncollected_payment]
    //   );
    //   return (eval(total.join("+")) / 10000).toFixed(2);
    // },
    // groupInvoicedUncollected(item) {
    //   const total = _.concat(
    //     item.userInfo.map((v) => v.invoicedUncollected),
    //     [item.invoicedUncollected]
    //   );
    //   return (eval(total.join("+")) / 10000).toFixed(2);
    // },
    // groupNotInvoicedUncollected(item) {
    //   const total = _.concat(
    //     item.userInfo.map((v) => v.notInvoicedUncollected),
    //     [item.notInvoicedUncollected]
    //   );
    //   return (eval(total.join("+")) / 10000).toFixed(2);
    // },
    async save() {
      // this.user.target_value = this.user.target;
      await this.$http.put(`home/target`, this.users);
      if (this.user.role == 0) {
        await this.$http.put(`home/company_target`, this.user.target_value);
      }
      this.$message.success("设置成功");
      this.fetch();
      this.dialogVisible = false;
    },
    async yearSave() {
      // this.user.target_value = this.user.target;
      await this.$http.put(`home/target`, this.users);
      if (this.user.role == 0) {
        await this.$http.put(
          `home/company_year_target`,
          this.user.group_annual_goal
        );
      }

      this.$message.success("设置成功");
      this.fetch();
      this.yearDialogVisible = false;
    },
    screen() {
      this.fetchImplementedAmount(this.dates);
    },
    editItem(item) {
      if (item.to) {
        this.$router.push(item.to);
      } else if (item.type == "target" && [0, 8, 5].includes(this.user.role)) {
        this.dialogVisible = true;
      } else if (
        item.type == "yearTarget" &&
        [0, 8, 5].includes(this.user.role)
      ) {
        this.yearDialogVisible = true;
      }
    },
    to(item) {
      if (item.to) {
        this.$router.push(item.to);
      } else if (item.type == "target" && [0, 8].includes(this.user.role)) {
        this.dialogVisible = true;
      } else if (item.type == "yearTarget" && [0, 8].includes(this.user.role)) {
        this.yearDialogVisible = true;
      } else if (item.type == "target" && [5].includes(this.user.role)) {
        this.groupMonthDialogVisible = true;
      } else if (item.type == "yearTarget" && [5].includes(this.user.role)) {
        this.groupYearDialogVisible = true;
      } else if (item.type == "dayPreBook" && [5].includes(this.user.role)) {
        this.groupDayDialog = true;
      } else if (item.type == "monthPreBook" && [5].includes(this.user.role)) {
        this.groupMonthDialog = true;
      }
    },
    handleClose() {
      this.dialogExport = false;
      this.dialogTeacherExport = false;
    },
    async exportTeacherSubmit(row, done) {
      let path =
        this.exportTeacherType == "course"
          ? `home/exportTeacher/${this.dataDate.dataMonth}`
          : `home/exportTeacherYearData`;

      const res = await this.$http.post(
        path,
        {
          ...row,
          ...this.dataDate,
        },
        {
          responseType: "arraybuffer",
        }
      );
      const link = document.createElement("a");
      let blob = new Blob([res.data], { type: "application/x-xls" });
      link.href = URL.createObjectURL(blob);
      const title = `讲师排课数据${dayjs(this.dataDate.dataMonth).format(
        "YYYY"
      )}`;
      link.download = `${title}.xlsx`;
      link.click();
      document.body.appendChild(link);
      done();
      this.dialogTeacherExport = false;
      this.all = false;
      this.exportTeacherForm.teachers = [];
      this.$message.success("导出成功");

      console.log(this.$refs.multipleSelect);

      if (this.$refs.multipleSelect) {
        this.$refs.multipleSelect.clear();
      }
    },
    async exportSubmit(row, done) {
      if (!this.exportForm.type) {
        return this.$message.warning("请选择导出类型");
      }

      if (this.exportForm.type == 1) {
        row.daterange = [];
        this.exportForm.daterange = [];
      }

      let path =
        this.exportType === "user"
          ? `home/export/${this.dataYear || "cur_time"}`
          : `home/exportGroups/${this.dataYear || "cur_time"}`;
      let res = await this.$http.post(path, row, {
        responseType: "arraybuffer",
      });

      if (row.type == 1) {
        delete row.daterange;
      }
      if (row.daterange) {
        var startTime = dayjs(row.daterange[0]).format("YYYY-MM-DD");
        var endTime = dayjs(row.daterange[1]).format("YYYY-MM-DD");
      }
      const link = document.createElement("a");
      let blob = new Blob([res.data], { type: "application/x-xls" });
      link.href = URL.createObjectURL(blob);
      const title = `销售数据${
        row.daterange ? startTime + "-" + endTime : dayjs().format("YYYY-MM-DD")
      }`;
      link.download = `${title}.xlsx`;
      link.click();
      document.body.appendChild(link);
      done();
      this.dialogExport = false;

      this.exportForm.type = "";
      this.exportForm.daterange = [];
    },
    exportData() {
      this.dialogExport = true;
      this.exportType = "user";
    },
    exportGroupRankData() {
      this.dialogExport = true;
      this.exportType = "group";
    },
    exportTeacherData() {
      this.dialogTeacherExport = true;
      this.exportTeacherType = "course";
    },
    exportTeacherAllData() {
      this.dialogTeacherExport = true;
      this.exportTeacherType = "year";
    },
    async fetchMonthData() {
      this.monthData = (await this.$http.get("home/getMonthData")).data;
      const res = this.monthData.map((v) => {
        return v.sum;
      });
      this.barData.series[0].data = res;
    },
    async fetchOrderState() {
      const res = await this.$http.get("home/schedules_state");
      this.pieData = res.data;
      this.editTargetTip();
    },

    async fetchTeacherAmount() {
      const res = await this.$http.post(`home/teacher_amount`, this.dataDate);
      this.teacherRank = res.data;
    },
    async fetchTeacherDay() {
      this.wayRankLoading = true;
      const res = await this.$http.post(`home/teacher_day`, {
        ...this.dataDate,
        sort: this.waySort,
        wayrankYear: this.wayrankYear,
      });
      this.wayRank = res.data;
      this.wayRankLoading = false;
    },
    async fetchBackDay() {
      const res = await this.$http.post(`home/back_moneny`, this.dataDate);
      this.backRank = res.data;
    },
    async fetchImplementedAmount(dates) {
      const res = await this.$http.post(
        `home/implementedAmount_rank/${dates ? dates : this.implementRanking}`,
        this.dataDate
      );
      this.implementedRank = res.data;
    },
    async fetchSaleRank() {
      let ranking="";

      if(this.ranking_type=="sale_amount"){
        ranking=this.ranking;
      }

      if(this.ranking_type=="implement_amount"){
        ranking=this.implementRanking;

      }
      const res = await this.$http.post(`home/sale_rank/${ranking}`, {
        ...this.dataDate,
        dates: this.saleRank_dates,
        ranking_type:this.ranking_type
      });
      this.saleRank = res.data;
    },
    async fetchGroupRanks() {
      const res = await this.$http.post(`home/group_rank/${this.ranking}`, {
        ...this.dataDate,
        dates: this.saleRank_dates,
      });
      this.groupRanks = res.data;
    },
    async changPage({ pageSize, currentPage }) {
      this.saleQuery.page = currentPage;
      this.saleQuery.limit = pageSize;
      this.getList();
    },
    async rankChangPage({ pageSize, currentPage }) {
      this.implementedAmountQuery.page = currentPage;
      this.implementedAmountQuery.limit = pageSize;
      this.getImplementedRank();
    },

    async teacherRankChangPage({ pageSize, currentPage }) {
      this.teacherAmountQuery.page = currentPage;
      this.teacherAmountQuery.limit = pageSize;
      this.getTeacherList();
    },

    async toList(item, type) {
      if (type == "implemented_rank") {
        this.obj_user = item._id;
        this.getImplementedRank();
      } else if (type == "sale_rank") {
        this.obj_user = item.user._id;
        this.getList();
      } else if (type == "teacherRank") {
        this.obj_teacher = item._id;
        this.getTeacherList();
      }
    },

    async getImplementedRank() {
      const res = await this.$http.post(
        `home/implementedAmount_rank/list/${
          this.dates ? this.dates : this.implementRanking
        }`,
        {
          params: {
            query: {
              implementedAmountQuery: this.implementedAmountQuery,
              dataMonth: this.dataDate.dataMonth,
              where: {
                user: this.obj_user,
              },
            },
          },
        }
      );
      this.implementedAmountRankData = res.data.data;
      this.implementedPage.total = res.data.total;
      this.implementedAmountRankDialog = true;
    },
    async getTeacherList() {
      const res = await this.$http.post(`home/teacher_amount/list`, {
        params: {
          query: {
            teacherAmountQuery: this.teacherAmountQuery,
            dataMonth: this.dataDate.dataMonth,
            where: {
              teacher: this.obj_teacher,
            },
          },
        },
      });
      this.teacherRankData = res.data.data;
      this.teacherRankPage.total = res.data.total;
      this.teacherRankDialog = true;
    },

    async getList() {
      const res = await this.$http.post(`home/sale_rank/list/${this.ranking}`, {
        params: {
          query: {
            saleQuery: this.saleQuery,
            dataMonth: this.dataDate.dataMonth,
            where: {
              user: this.obj_user,
            },
          },
        },
      });
      this.saleRankData = res.data.data;
      this.page.total = res.data.total;
      this.saleRankDialog = true;
    },
    // async fetchCostomerRank() {
    //   const res = await this.$http.get("home/customer_rank");
    //   this.costomersRank = res.data;
    // },
    async fetchDailyReminder() {
      const res = await this.$http.get("home/daily_reminder");
      this.dailyReminder = res.data;
    },
    async fetchTarget() {
      const res = await this.$http.get("home/performance_target");
      this.performance_target = res.data;
    },
    goCustomer(item) {
      this.$router.push(`customers/list?customerId=${item._id}`);
    },
    goSchedule(item) {
      this.$router.push(`schedules/list?scheduleId=${item._id}&new=true`);
    },

    async fetchUser() {
      if (this.user.role == 5) {
        this.query.where = {
          ...this.query.where,
          _id: {
            $in: this.user.userInfo.concat(this.user._id),
          },
        };
      }
      // const res = await this.$http.get("users", {
      //   params: {
      //     query: this.query,
      //   },
      // });

      const res = await this.$http.get("home/target/users", {
        params: {
          query: this.query,
        },
      });
      this.users = res.data.map((v) => {
        return {
          ...v,
          userInfo: v?.userInfo?.filter((v) => v.role !== 4) || [],
        };
      });
    },

    async fetchTeachers() {
      const res = await this.$http.get("teachers/option");
      this.teachers = res.data.map((v) => {
        return {
          label: v.name,
          value: v._id,
        };
      });
    },
    editTargetTip() {
      const endMonth = this.dayjs().endOf("month").format("DD");
      const day = this.dayjs(this.date).format("DD");
      if (day == endMonth) {
        this.targetTip = true;
        this.$message({
          showClose: false,
          message: "本月快结束了，别忘了重新设定下月业绩目标哦~",
          type: "warning",
          duration: 4000,
          center: true,
          //  offset:60
        });
        // this.targetTip = false;
      } else {
        this.targetTip = false;
      }
    },
    async fetchDataAnalysis() {
      const data_analysis = await this.$http.post(
        `home/dataAnalysis`,
        this.dataQuery
      );
      this.dataOption = data_analysis.data.dataOption;
      this.monthSaleMoney = data_analysis.data.monthSaleMoney;
      this.monthApplyMoney = data_analysis.data.monthApplyMoney;
      this.initChart1();
    },
    setRanking(){
      this.ranking_type="sale_amount";
      this.implementRanking="";
    },
    setimplementRanking(){
      this.ranking_type="implement_amount";
      this.fetchSaleRank();
    },

    async fetch() {
      const res = await this.$http.post(`home/data`, this.dataDate);
      const ret = await this.$http.get("home/recent_followup");
      if (this.user && this.user.role == 5) {
        const groups = (
          await this.$http.post(`home/group_information`, this.dataDate)
        ).data;
        this.monthUserInfo = groups.monthUserInfo;
        this.yearUserInfo = groups.yearUserInfo;

        const groupAmount = (
          await this.$http.post(`home/group_reservation_amount`, this.dataDate)
        ).data;
        this.monthUserAmount = groupAmount.monthUserInfo;
        this.dayUserAmount = groupAmount.dayUserInfo;
      }

      let data = res.data;
      let followupCustomers = ret.data;
      this.followupCustomers = followupCustomers.sort(
        (a, b) => a.days_keyPoint - b.days_keyPoint
      );
      this.option = data.option;
      this.option.data.map((v) => {
        if (v.to) {
          v.click = () => this.$router.push(v.to);
        }
      });

      const options = this.option.data.filter((v) => {
        if (this.user.role == 5) {
          return v.type !== "payBack";
        } else {
          return v.type !== "meTarget";
        }
      });

      this.option.data = options;
      this.customerOption = data.customerOption;
      // this.customerOption.data.map((v) => {
      //   if (v.to) {
      //     v.click = () => this.$router.push(v.to);
      //   }
      // });
      this.gaugeConfig = data.gaugeConfig;
      this.targetData = data.targetData;
      this.salesVolume = (data.salesVolume / 10000).toFixed(2);
      this.loading = false;
      // loading.close();
    },
    async initChart1() {
      let chartDom = this.$refs.chart1;
      let chartDom2 = this.$refs.chart2;
      let myChart = this.$echarts.init(chartDom);
      let myChart2 = this.$echarts.init(chartDom2);
      myChart.setOption(this.monthSaleMoney, true);
      myChart2.setOption(this.monthApplyMoney, true);
    },

    async dataAnalysis() {
      this.dataDialogVisible = true;
      this.fetchDataAnalysis();
      // this.initChart1();
    },
    async fetchGroupPayback() {
      const res = await this.$http.get("home/groupPayback");
      this.groups = res.data;
    },
    async fetchGroup() {
      const res = await this.$http.get("users", {
        params: {
          query: {
            limit: 0,
            where: { role: 5 },
            populate: [
              {
                path: "schedules",
                select: "payBack order_amount",
              },
              {
                path: "userInfo",
                select: "payBack order_amount realname",
                populate: "schedules",
              },
            ],
          },
        },
      });
      let groups = res.data.data;
    },
  },
  created() {
    // this.fetchCostomerRank();
    this.fetch();
    this.fetchUser();
    this.fetchTeachers();
    this.fetchGroup();
    this.fetchOrderState();
    this.fetchMonthData();
    this.fetchSaleRank();
    this.fetchDailyReminder();
    this.fetchImplementedAmount();
    this.fetchTeacherAmount();
    this.fetchTeacherDay();
    this.fetchBackDay();
    this.fetchGroupRanks();
    // this.fetchGroupPayback();
  },
};
</script>

<style>
@media only screen and (max-width: 1280px) {
  .name-width {
    width: 100px !important;
  }
  .history-width {
    width: 176px !important;
  }
}
@media only screen and (max-width: 768px) {
  .history-width {
    width: 86px !important;
  }
  .post-width {
    width: 80px !important;
  }
  .day-width {
    width: 60px !important;
  }
  .table-title {
    font-size: 14px !important;
  }
  .th-title {
    font-size: 12px !important;
  }
  .option-count {
    font-size: 15px !important;
  }
  .option-title {
    font-size: 12px !important;
  }
  .img-size {
    width: 28px !important;
    height: 28px !important;
  }
  .daterange {
    display: inline !important;
    line-height: 40px;
  }
  .implemented {
    display: inline !important;
  }
}
.implemented {
  display: flex;
  justify-content: space-between;
}
.daterange {
  display: flex;
}
.day-width {
  width: 80px;
}
.history-width {
  width: 176px;
}
.name-width {
  width: 110px;
}
.user-w {
  width: 60px;
}
.post-width {
  width: 180px;
}
.home ::-webkit-scrollbar {
  width: 3px !important;
}

.home ::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: rgba(49, 49, 49, 0.1) !important;
}

.home ::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(49, 49, 49, 0.1) !important;
}

.home .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 66% !important;
}

.home .el-date-editor .el-range-separator {
  width: 10% !important;
}

.data-box .item-info .title {
  font-size: 20px !important;
}

.avue-data-display .title {
  font-size: 14px !important;
}

.avue-data-display .count {
  font-size: 22px !important;
}

.avue-data-display .item {
  margin: 14px 0 !important;
}

.avue-echart-pie {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  padding: 1rem 2rem;
  border-radius: 0.6rem;
}

.avue-echart-pie__title,
.avue-echart-tabs__item {
  height: auto !important;
}

.avue-echart-bar {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  margin-top: 1rem;
  padding: 1rem 2rem;
}

/* .el-col-5 {
  width: 19.83% !important;
} */

.card-1 {
  margin-top: 0.5rem;
  height: 320px;
  overflow: auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.more {
  position: absolute;
  right: 10px;
  top: 6px;
  font-size: 12px;
  border-radius: 50%;
  background-color: #fff;
  /* box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2); */
  /* height: 50px; */
}

.card-1 .el-card__body {
  width: 100%;
  overflow: auto;
  min-height: 320px;
}

.home .el-card {
  border: none !important;
}

.card-2 {
  height: 428px;
  /* height: 320px; */
  overflow: auto;
  display: flex;
  justify-content: center;
}

.card-2 .el-card__body {
  width: 100%;
  min-height: 428px;
  overflow: auto;
}

.teacher-card {
  margin-top: 1rem;
  padding: 1rem;
  min-height: 320px;
}

.box-shadow {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
}

td {
  padding: 0.8rem 0rem;
}

.jump {
  position: absolute;
  right: 8px;
  top: 16%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 36px;
  height: 42px;
  border-radius: 0.4rem;
  text-align: center;
  font-size: 12px;
}

.img-size {
  width: 46px;
  height: 46px;
}

.option-title {
  font-size: 15px;
  color: #444;
}

.option-count {
  font-size: 20px;
  font-weight: 500;
}

.th-title {
  font-size: 14px;
}

.table-title {
  font-size: 16px;
}
.group-table td {
  padding: 0.4rem 0rem;
}

.group-table thead {
  background: aliceblue;
}

.home .el-date-editor.el-input,
.year .el-date-editor.el-input__inner {
  width: 90px !important;
}

.home .el-date-editor.el-input,
.month .el-date-editor.el-input__inner {
  width: 140px !important;
}

.home .icon {
  height: 17px;
  width: 17px;
  text-align: center;
  line-height: 17px;
  border-radius: 50%;
  background: #3056d3;
}

.card-item .el-card__body {
  padding: 10px 16px !important;
}
.way_rank .el-card__body {
  position: relative;
  overflow: hidden;
  height: 100% !important;
  table-layout: fixed;
  overflow-y: scroll;
  padding: 0;
  margin: 0.5rem;
  padding-right: 0;
  margin-right: 0;
}
.way_rank {
  overflow: hidden !important;
}

.way_title {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
</style>
