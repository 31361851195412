<template>
  <div class="develop_teahcer">
    <div class="search my-3"></div>
    <avue-crud
      ref="crud"
      :data="teacherData"
      :option="developOption"
      :page.sync="page"
      v-model="teacher"
      @on-load="changPage"
      :table-loading="loading"
      @search-change="search"
      @row-del="remove"
      @row-save="create"
      @row-update="update"
      :upload-preview="uploadPreview"
      :upload-after="uploadAfter"
      :upload-before="uploadBefore"
    >
      <template slot="interview_date" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="interview_dates(scope.row.interview_date)"
          placement="top-start"
        >
          <span>
            {{ interview_date(scope.row.interview_date) }}
          </span>
        </el-tooltip>
      </template>

      <template slot="contact_time" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="concatTime(scope.row.communication_times[0])"
          placement="top-start"
          v-if="scope.row.communication_times[0]"
        >
          <span>
            {{ concatTime(scope.row.communication_times[0]) }}
          </span>
        </el-tooltip>
      </template>

      <template slot="sign_time" slot-scope="scope">
        <span>
          {{ scope.row.sign_time | formatDateY }}
        </span>
      </template>

      <!-- <template slot-scope="scope" slot="teacher_filesForm">
        {{ scope.row.teacher_files }}
        <el-upload  multiple :limit="1" 
        :auto-upload="false"
        :show-file-list="true" :file-list="scope.row.teacher_files">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </template> -->

      <template slot-scope="scope" slot="menu">
        <el-tooltip content="下载讲师资料或者课件" class="ml-auto">
          <el-button
            icon="el-icon-download"
            @click="downloadInfos(scope.row)"
            :size="scope.size"
            :type="scope.type"
            >下载资料</el-button
          >
        </el-tooltip>
      </template>
    </avue-crud>

    <el-dialog
      title="沟通进展"
      :visible.sync="dialogVisible"
      width="74%"
      :before-close="dataClose"
    >
      <el-card class="mb-4">
        <el-form :inline="true">
          <el-form-item label="沟通日期">
            <el-date-picker
              v-model="record.time"
              type="datetime"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="沟通方式">
            <el-select v-model="record.way" placeholder="请选择">
              <el-option
                v-for="item in way_options"
                :key="item.id"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="沟通人">
            <el-select filterable v-model="record.user" placeholder="请选择">
              <el-option
                v-for="item in superintendents"
                :key="item.id"
                :label="item.realname"
                :value="item._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-input
            v-model="record.desc"
            type="textarea"
            placeholder="请输入沟通内容"
          ></el-input>

          <div class="text-center mt-2">
            <el-button type="primary" @click="onSubmit()">提交</el-button>
          </div>
        </el-form>
        <!-- <avue-form v-model="record" :option="records"> </avue-form> -->
      </el-card>
      <div class="block">
        <el-timeline
          v-if="_.get(editComm, 'communicate_progress.length', 0) > 0"
        >
          <!-- <div v-for="v in data" :key="v._id"> -->
          <el-timeline-item
            v-for="(item, index) in editComm.communicate_progress"
            :key="item._id"
            :timestamp="item.submitedAt | formatDate"
            placement="top"
          >
            <el-card>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="pr-2" v-if="item.user">
                    跟进人：
                    <span>{{ realname(item.user) }}</span>
                    <!-- <span>{{ _.get(formRow, "history_user[0].realname") }}</span> -->
                  </div>
                  <div class="history-info">
                    沟通日期：
                    <span class="createdAt">
                      {{ item.time | formatDateY }}
                    </span>
                    <div class="pdl-3">
                      <span>沟通方式：</span>
                      <span class="text-danger font-weight-bold">
                        {{ item.way }}
                      </span>
                    </div>
                  </div>
                  <div class="pt-3">{{ item.content }}</div>
                </div>
                <div class="pr-4">
                  <el-button
                    @click="detach(index)"
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <div v-else class="text-center pb-2">暂无记录</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { formatDateY, validMobile, formatDate } from "../../filters";
import { ossClient } from "../../plugins/upload";
import editItem from "./edit.vue";
import { Docxer } from "../../plugins/docx";

export default {
  data() {
    return {
      loading: false,
      teacher: {
        communicate_progress: [],
      },

      dialogVisible: false,
      editComm: "",
      record: {
        time: "",
        desc: "",
        way: "",
        user: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 50, 100],
      },
      teacherData: [],
      queyPage: {
        page: 1,
        limit: 10,
      },
      file_names: {},
      dataQuery: {
        name: "",
      },
      way_options: [],
      select_list: [],
      superintendents: [],
    };
  },
  computed: {
    developOption() {
      return {
        border: true,
        searchMenuSpan: 4,
        dialogWidth: 900,
        menuWidth: 280,
        viewBtn: true,
        align: "center",
        editBtn: true,
        delBtn: true,
        dialogClickModal: false,
        index: true,
        indexLabel: "序号",
        translate: false,
        column: [
          {
            label: "讲师姓名",
            prop: "name",
            border: true,
            span: 8,
            search: true,
            formslot: true,
            searchSpan: 4,
            width: 100,
            rules: [
              {
                required: true,
                message: "请输入讲师姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "联系电话",
            prop: "mobile",
            border: true,
            span: 8,
            // search: true,
            width: 200,
            rules: [
              {
                required: true,
                message: "请填写手机号",
                trigger: "blur",
              },
              {
                required: true,
                trigger: "blur",
                validator: (rule, value, callback) => {
                  if (!validMobile(value)) {
                    callback(new Error("手机号码格式错误"));
                  } else {
                    callback();
                  }
                },
              },
            ],
          },
          {
            label: "联系时间",
            prop: "contact_time",
            border: true,
            span: 8,
            search: false,
            slot: true,
            width: 100,
            display: false,
          },

          {
            label: "常驻地",
            prop: "resident_city",
            border: true,
            span: 8,
            filterable: true,
            // search: true,
            type: "cascader",
            props: {
              label: "value",
              value: "value",
            },
            span: 8,
            dicUrl: "getCity",
            checkStrictly: true,
            rules: [
              {
                required: true,
                message: "请选择常驻城市",
                trigger: "blur",
              },
            ],
          },
          {
            label: "推荐人",
            prop: "recommend_user",
            border: true,
            span: 8,
            slot: true,
            searchFilterable: true,
            // search: true,
            width: 100,
            rules: [
              {
                required: true,
                message: "请填写推荐人",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "擅长领域",
          //   prop: "good_areas",
          //   border: true,
          //   search:true,
          //   span: 8,
          //   type: "select",
          //   dicUrl: `dictionaries/type/schedule.category`,

          // },
          {
            label: "性别",
            prop: "sex",
            props: {
              label: "label",
              value: "value",
            },
            span: 8,
            type: "radio",
            dicData: [
              {
                label: "男",
                value: 1,
              },
              {
                label: "女",
                value: 2,
              },
            ],
            mock: {
              type: "dic",
            },
            showColumn: false,
            hide: true,
          },

          {
            label: "擅长领域",
            prop: "good_areas",
            props: {
              label: "label",
              value: "label",
            },
            // search: true,
            type: "select",
            dicUrl: `dictionaries/type/schedule.category`,
            rules: [
              {
                message: "请选择擅长领域",
                trigger: "blur",
              },
            ],
            span: 8,
          },

          {
            label: "市场价格",
            prop: "market_price",
            border: true,
            span: 8,
            showColumn: false,
            hide: true,
            slot: true,
            width: 120,
            type: "number",
          },
          {
            label: "是否签约",
            prop: "is_signed",
            props: {
              label: "label",
              value: "value",
            },
            searchSpan: 4,
            span: 8,
            type: "radio",
            search: true,
            dicData: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 2,
              },
            ],
            mock: {
              type: "dic",
            },
          },
          {
            label: "签约时间",
            prop: "sign_time",
            showColumn: false,
            hide: false,
            border: true,
            span: 8,
            slot: true,
            display: this.teacher.is_signed == 1 ? true : false,
            type: "date",
            whidth: 120,
          },
          {
            label: "师资类型",
            prop: "types",
            props: {
              label: "label",
              value: "label",
            },
            showColumn: false,
            hide: true,
            type: "select",
            multiple: true,
            span: 8,
            dicData: [
              {
                label: "已签约",
                value: "已签约",
              },
              {
                label: "备选老师",
                value: "备选老师",
              },
              {
                label: "储备师资",
                value: "储备师资",
              },
              {
                label: "暂未有合作意向",
                value: "暂未有合作意向",
              },
            ],
          },
          {
            label: "沟通时间",
            prop: "communication_times",
            type: "dynamic",
            span: 24,
            hide: true,
            children: {
              align: "center",
              headerAlign: "left",
              rowAdd: (done) => {
                this.$message.success("新增成功");
                done({
                  // input: "默认值",
                });
              },
              rowDel: (row, done) => {
                this.$message.success("删除成功");
                done();
              },
              column: [
                {
                  width: 200,
                  label: "时间",
                  type: "date",
                  prop: "time",
                },
                {
                  label: "备注",
                  type: "textarea",
                  prop: "desc",
                },
              ],
            },
          },
          {
            label: "试讲地点",
            prop: "live_palce",
            props: {
              label: "label",
              value: "label",
            },
            showColumn: false,
            hide: true,
            type: "select",
            span: 12,
            dicData: [
              {
                label: "现场",
                value: "现场",
              },
              {
                label: "线上",
                value: "线上",
              },
            ],
          },
          {
            label: "试讲课题",
            prop: "live_title",
            border: true,
            span: 8,
            showColumn: false,
            hide: true,
            slot: true,
            span: 12,
            slot: true,
            width: 300,
          },
          {
            label: "讲师资料",
            prop: "teacher_files",
            border: true,
            span: 24,
            slot: true,
            whidth: 120,
            showColumn: false,
            hide: true,
            type: "upload",
            action: "upload",
            formsolt: true,
            multiple: true,
            hide: true,
            showColumn: false,
            props: {
              label: "name",
              value: "url",
            },
            propsHttp: {
              url: "url",
              name: "originalname",
            },
          },
          {
            label: "沟通进展",
            prop: "communicate_progress",
            border: true,
            formslot: true,
            hide: true,
            showColumn: false,
            span: 24,
            display: false,
          },

          {
            label: "试讲课件",
            prop: "interview_course",
            border: true,
            span: 24,
            slot: true,
            whidth: 120,
            showColumn: false,
            hide: true,
            type: "upload",
            action: "upload",
            props: {
              label: "name",
              value: "url",
            },
            propsHttp: {
              url: "url",
              name: "originalname",
            },
            multiple: true,
            hide: true,
            showColumn: false,
            slot: true,
          
            propsHttp: {
              url: "url",
              name: "name",
            },
          },
          {
            label: "试讲反馈",
            prop: "interview_back",
            border: true,
            type: "textarea",
            span: 24,
            slot: true,
            width: 300,
            showColumn: false,
            hide: true,
          },
          {
            label: "师资评审",
            prop: "teacher_check",
            border: true,
            type: "textarea",
            showColumn: false,
            hide: true,
            span: 24,
            slot: true,
            width: 300,
          },
          {
            label: "认领小组",
            prop: "group",
            props: {
              label: "label",
              value: "value",
            },
            showColumn: false,
            hide: true,
            type: "select",
            span: 8,
            dicUrl: `develop-teacher/group/options`,
          },
        ],
      };
    },
    realname() {
      return (user) => {
        return this.superintendents.find((v) => v.id == user)?.realname || "";
      };
    },

    interview_date() {
      return (arr) => {
        if (!arr) {
          return "";
        }

        if (!arr.length) {
          return "";
        }
        return this.$filters.formatDateH(arr[0]);
      };
    },
    concatTime() {
      return (item) => {
        if (!item) return "";
        if (!item.time) return "";
        return this.$filters.formatDateT(item.time);
      };
    },
    interview_dates() {
      return (arr) => {
        if (!arr) {
          return "";
        }
        if (!arr.length) {
          return "";
        }
        return arr.reduce((str, cur) => {
          return (str += this.$filters.formatDateH(cur));
        }, "");
      };
    },
  },
  components: {},
  methods: {
    formatDate,
    formatDateY,
    uploadAfter(res, done) {
      this.file_names[res.url] = res.originalname;
      console.log(this.file_names);
      console.log(this.teacher);

      done();
    },
    uploadBefore(res, done) {
      done();
    },
    async fetchType() {
      const res = await this.$http.get("dictionaries/type/contact.way");
      this.way_options = res.data.sort();
    },

    async remove(row) {
      await this.$confirm("是否确认删除此数据？");
      await this.$http.delete(`develop-teacher/` + row._id);

      this.$message.success("删除成功");
      this.fetchData();
    },
    async changPage({ pageSize, currentPage }) {
      this.queyPage.page = currentPage;
      this.queyPage.limit = pageSize;
      this.fetchData();
    },
    selectionChange(vals) {
      this.select_list = vals;
    },

    showComm(scope) {
      this.editComm = scope.row;
      this.dialogVisible = true;
    },
    dataClose() {
      this.dialogVisible = false;
    },
    async onSubmit() {
      this.record.submitedAt = new Date();
      if (this.record && this.record.user == undefined) {
        this.record.user = this.user._id;
      }
      this.editComm.communicate_progress.push(this.record);
      await this.updateItem(this.editComm);
      this.record = {
        time: "",
        desc: "",
        way: "",
        user: "",
      };
    },

    uploadPreview(file, column, done) {
      const url = file.url;
      const ext = url.split(".").pop();

      const isDoc = ["doc", "docx", "ppt"].includes(ext);

      const isPdf = ["pdf"].includes(ext);

      const isImage = ["png", "jpeg", "jpg"].includes(ext);

      if (isImage) {
        return done();
      }

      if (isPdf) {
        return window.open(url);
      }

      if (!isDoc && !isImage) {
        return this.$message.error("文件类型暂不支持预览");
      }

      if (isDoc) {
        return Docxer.viewDoc(url);
      }
    },

    async updateItem(row) {
      await this.$http.put(`develop-teacher/${row._id}`, row);
      this.$message.success("更新成功");
    },
    async detach(index) {
      try {
        await this.$confirm("是否确认删除？");
      } catch (e) {
        return;
      }
      this.editComm.communicate_progress.splice(index, 1);
      this.updateItem(this.editComm);
    },

    async fetchUser() {
      const ret = await this.$http.get(`users`, {
        params: {
          query: {
            limit: 0,
            where: {
              role: { $in: [1, 4, 5, 0] },
            },
          },
        },
      });
      const superintendent = ret.data.data;
      this.superintendents = superintendent;

      const res = await this.$http.get(`users/director`);
      const users = res.data;
      users.unshift({
        _id: "空",
        realname: "空",
      });
      this.users = users;

      if (this.option && this.option.column) {
        const field = this.option.column.find((v) => v.prop === "user");
        field.dicData = this.users;
      }
    },

    Addcommunicate(row) {
      let list = row.communicate_progress ?? [];
      list.push({
        user: "",
        action: "",
        time: new Date(),
        way: "",
        desc: "",
        contact_way: "",
        isEdit: true,
      });
    },
    updateComm(val, index, row) {
      this.$set(row.communicate_progress, index, val);
    },
    async create(row, done) {
      await this.$http.post("develop-teacher", row);
      this.$message.success("创建成功");
      this.fetchData();
      done();
    },

    async update(row, index, done) {
      if (!row.communication_times) {
        row.communication_times = [];
      }
      if (!row.teacher_files) {
        row.teacher_files = [];
      }
      if (!row.interview_course) {
        row.interview_course = [];
      }
      await this.$http.put(`develop-teacher/${this.teacher._id}`, row);
      this.$message.success("更新成功");
      this.fetchData();
      done();
    },

    search(val, done) {
      this.dataQuery.name = val.name || "";
      this.dataQuery.mobile = val.mobile || "";
      this.dataQuery.user = val.user || "";
      this.dataQuery.city = val.city || "";
      this.dataQuery.is_signed = val.is_signed || "";
      done();
      this.fetchData();
    },

    // 获取师资开发列表
    async fetchData() {
      const { data } = await this.$http.get("develop-teacher", {
        params: {
          ...this.queyPage,
          where: this.dataQuery,
        },
      });
      this.page.total = data.total;
      this.teacherData = data.data;
    },

    async downloadInfos(row) {
      const techer_files = (row.teacher_files ?? []).map((v, index) => {
        return {
          url: v.url,
          filename: v.name,
        };
      });

      const interview_course = (row.interview_course ?? []).map((v, index) => {
        return {
          url: v.url,
          filename: v.name,
        };
      });
      const files = techer_files.concat(interview_course);

      if (!files.length) {
        return this.$message.error("暂无资料");
      }

      await ossClient.batchDownload(
        files,
        () => {
          this.$message.success("下载成功");
        },
        () => {
          this.$message.error("下载失败,请重试");
        },
        row.name + "资料"
      );

      console.log(files);
    },
    uploadDelete(file, column) {
      const index = this.teacher[column.prop].findIndex((v) => v.url === file.url);
      if (index > -1) {
          this.teacher[column.prop].splice(index, 1);
        }

        console.log(index,this.teacher)
        return true;
    },
  },

  created() {
    this.fetchData();
    this.fetchType();
    this.fetchUser();
  },
};
</script>

<style>
.courseware .text-truncate {
  color: #409eff;
}
</style>
