<template>
    <div class="notice-list w-100 h-100">
        <avue-crud class="h-100" ref="flie_crud" @row-del="remove" @row-save="create" @row-update="update"
            :data="noticeData" :option="noticeOption" :page.sync="page" @on-load="changPage"></avue-crud>
    </div>
</template>

<script>

export default {
    data() {
        return {
            dataQuery: {
                name: "",
            },
            noticeData: [],
            noticeOption: {
                translate: false,
                refreshBtn: false,
                searchShowBtn: false,
                columnBtn: false,
                column: [
                    {
                        label: "通知类型",
                        prop: "type",
                        border: true,
                        type: "radio",
                        dicData: [
                            {
                                label: "一般",
                                value: 'usually',
                            },
                            {
                                label: "重要",
                                value: 'important',
                            },
                        ],
                        span: 24,
                        slot: true,
                        width: 100,
                        rules: [
                            {
                                required: true,
                                message: "请选择通知类型",
                                trigger: "blur",
                            },
                        ],
                    },
                    {
                        label: "通知名称",
                        prop: "name",
                        span: 24,
                        slot: true,
                        width: 300,
                        rules: [
                            {
                                required: true,
                                message: "请填写通知名称",
                                trigger: "blur",
                            },
                        ],
                    },
                    {
                        label: "通知内容",
                        prop: "content",
                        type: "textarea",
                        span: 24,
                        minRows: 2,
                        slot: true,
                        width: 200,
                        showColumn: false,
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: "请填写通知内容",
                                trigger: "blur",
                            },
                        ],
                    },
                ],
            },
            page: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                pageSizes: [10, 20, 30, 40, 50],
            },
        }
    },
    methods: {
        changPage() {

        },
        async fetchData() {
            const { data } = await this.$http.get("notice", {
                params: {
                    ...this.page,
                    where: this.dataQuery,
                },
            });
            this.page.total = data.total;
            this.noticeData = data.data;
        },
        async create(row, done) {
            await this.$http.post("notice", row);
            this.$message.success("创建成功");
            this.fetchData();
            done();
        },
        async remove(row) {
            await this.$confirm("是否确认删除此数据？");
            await this.$http.delete(`notice/` + row._id);
            this.$message.success("删除成功");
            this.fetchData();
        },
        async update(row, index, done) {
            await this.$http.put(`notice/${row._id}`, row);
            this.$message.success("更新成功");
            this.fetchData();
            done();
        },
    },
    created() {
        this.fetchData()
    }
}

</script>